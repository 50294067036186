import store from '@/store';

class WindowSizeListenerService {
  private throttlingTimeOut: number | null = null;

  private static setWindowSize(): void {
    store.commit('layoutModule/windowWidth', window.innerWidth);
  }

  public initResizeEventListener(): void {
    WindowSizeListenerService.setWindowSize();

    window.addEventListener('resize', () => {
      this.setWindowSizeWithThrottling();
    });
  }

  private setWindowSizeWithThrottling(): void {
    if (this.throttlingTimeOut) {
      window.clearTimeout(this.throttlingTimeOut);
    }

    this.throttlingTimeOut = window.setTimeout(() => {
      WindowSizeListenerService.setWindowSize();
    }, 500);
  }
}

export const WindowSizeListenerServiceSingleton = new WindowSizeListenerService();
