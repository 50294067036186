import * as Sentry from '@sentry/vue';
import store from '@/store';
import { httpRequestFailureRetryDialogPayload } from '@/shared/http-requests-handler/http-requests-handler.const';
import { IHttpFailureDialogPayload } from '@/store/modules';

type HttpRequestsFunction<ResponseType, ArgumentType> = (...args: ArgumentType[]) => Promise<ResponseType>;

/**
 * This is a simple handler of the http requests.
 * * This class might be used in cases where you need to
 *   - either give user possibility to retry request
 *   OR
 *   - show simple error dialog with custom text and custom actions within dialog
 *
 * IMPORTANT
 *   - If you want to handle extra dialog actions first (which might be complex)
 *     before resolving request failure with `Error` value -> DO NOT USE IT
 *   - If you want to do extra complex action which might lead to repeating of the request
 *     -> read NOTES to `handleRequestWithCustomDialog` method
 *
 * Usage example:
 * ```
 *   const customer = await HttpRequestsHandler.handleRequestWithRetry(BackendService.getCustomer);
 * ```
 */
export class HttpRequestsHandler<ResponseType, ArgumentType> {
  private requestFunction: HttpRequestsFunction<ResponseType, ArgumentType> | undefined;
  private requestFunctionArguments: ArgumentType[] = [];
  private failurePromiseResolve: ((value: ResponseType | PromiseLike<ResponseType>) => void) | undefined;
  private failurePromiseReject: ((reason: unknown) => void) | undefined;
  private retryFailureError: Error | undefined;
  private customDialogPayload: IHttpFailureDialogPayload | undefined;
  private retryAttemptsCounter = 0;

  public static handleRequestWithRetry<ResponseType, ArgumentType>(
    requestFunc: HttpRequestsFunction<ResponseType, ArgumentType>,
    ...functionArguments: ArgumentType[]
  ): Promise<ResponseType> {
    return new HttpRequestsHandler<ResponseType, ArgumentType>()._handleRequestWithRetry(
      requestFunc,
      ...functionArguments,
    );
  }

  /*
   * Use this request handler when:
   *   - you want to give a user possibility to retry the request
   *   - it is obvious that requests might fail to offline or backend issues
   *   - when you don't need any extra actions when the request has failed
   *
   * IMPORTANT
   *   - Currently, user is forced to RETRY making request at least 2 times to see the `Cancel` button
   *
   * check `httpRequestFailureRetryDialogPayload` constant to see retry dialog texts
   * */
  private _handleRequestWithRetry(
    requestFunc: HttpRequestsFunction<ResponseType, ArgumentType>,
    ...functionArguments: ArgumentType[]
  ): Promise<ResponseType> {
    this.requestFunction = requestFunc;
    this.requestFunctionArguments = functionArguments;

    return requestFunc(...functionArguments).catch((error: Error) => {
      Sentry.captureException(error);
      return this.handleErrorWithRetry(error);
    });
  }

  /*
   * Use this request handler when:
   *   - on request failure you want to show simple dialog with custom text and buttons
   *
   * IMPORTANT:
   *   - Actions for buttons are fully under your control (outside of HttpRequestsHandler scope)
   *   - on request failure
   *     - first - the returned Promise will be rejected with the `Error`
   *     - second - the custom dialog will be shown
   * */
  public handleRequestWithCustomDialog(
    dialogPayload: IHttpFailureDialogPayload,
    requestFunc: HttpRequestsFunction<ResponseType, ArgumentType>,
    ...functionArguments: ArgumentType[]
  ): Promise<ResponseType> {
    this.setCustomDialogPayload(dialogPayload);
    this.requestFunction = requestFunc;
    this.requestFunctionArguments = functionArguments;

    return requestFunc(...functionArguments).catch((error) => {
      Sentry.captureException(error);
      return this.handleError(error);
    });
  }

  private handleError(error: Error): Promise<ResponseType> {
    return new Promise<ResponseType>((_, reject) => {
      reject(error);

      void store.dispatch('httpFailureDialog/showDialog', this.customDialogPayload);
    });
  }

  private setCustomDialogPayload(dialogPayload: IHttpFailureDialogPayload): void {
    this.customDialogPayload = { ...dialogPayload };
    this.customDialogPayload.buttons = [...dialogPayload.buttons];
  }

  private handleErrorWithRetry(error: Error): Promise<ResponseType> {
    return new Promise<ResponseType>((resolve, reject) => {
      this.failurePromiseResolve = resolve;
      this.failurePromiseReject = reject;
      this.retryFailureError = error;

      void store.dispatch('httpFailureDialog/showDialog', this.getRetryDialogPayload());
    });
  }

  private requestRetry(): void {
    if (this.failurePromiseResolve && this.requestFunction) {
      this.retryAttemptsCounter += 1;

      this.failurePromiseResolve(this._handleRequestWithRetry(this.requestFunction, ...this.requestFunctionArguments));
    }
  }

  private resolveFailureWithNull(): void {
    if (this.failurePromiseReject) {
      this.failurePromiseReject(this.retryFailureError);
    }
  }

  private getRetryDialogPayload(): IHttpFailureDialogPayload {
    const dialogPayload: IHttpFailureDialogPayload = Object.assign({}, httpRequestFailureRetryDialogPayload, {
      buttons: [...httpRequestFailureRetryDialogPayload.buttons],
    });
    const retryAttemptsMax = 2;

    // Show Cancel and Retry buttons
    if (this.retryAttemptsCounter >= retryAttemptsMax) {
      dialogPayload.buttons[0].action = () => {
        this.resolveFailureWithNull();
      };

      dialogPayload.buttons[1].action = () => {
        this.requestRetry();
      };
    } else {
      // Show only Retry button
      dialogPayload.buttons = [
        {
          ...dialogPayload.buttons[1],
          action: () => {
            this.requestRetry();
          },
        },
      ];
    }

    return dialogPayload;
  }
}
