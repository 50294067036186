


























































import { Component, Vue } from 'vue-property-decorator';

import store from '@/store';
import { idanaEditorBenefitsList } from './idana-product-card/idana-product-card.const';
import { IIdanaProductBenefitsItem } from './idana-product-card/idana-product-card.interfaces';

import IdanaProductCard from './idana-product-card/IdanaProductCard.vue';

@Component({
  components: { IdanaProductCard },
})
export default class OrderSubscriptionEditorPlan extends Vue {
  protected get isMediumMobileDevice(): boolean {
    return store.getters['layoutModule/isMobileDeviceWidthExcludingLarge'];
  }

  protected get isIdanaEditorSelected(): boolean {
    return store.state.order.idanaEditor.isEditorSelected;
  }

  protected set isIdanaEditorSelected(newIdanaEditor: boolean) {
    store.commit('order/isEditorSelected', newIdanaEditor);
  }

  protected get idanaEditorPrice(): number {
    return store.state.order.idanaEditor.price;
  }

  protected get benefitsList(): IIdanaProductBenefitsItem[] {
    return idanaEditorBenefitsList;
  }

  protected beforeDestroy(): void {
    store.dispatch('order/saveStateToLocalStorage');
  }
}
