import config from '@/config';
import { IStripeCouponInfo } from '@/store/modules';
import {
  CustomerRegistrationHTTPStatusCode,
  ICustomer,
  IFreeSubscriptionPayload,
  IMedicalSpecialty,
  IRegistrationInfo,
  IOnboardingPatchBody,
  IOnboardingStatus,
  IOrderSubscriptionPayload,
  IStripeIntentResponse,
  OnboardingStatusPatchKey,
} from './backend.interfaces';
import { getRequest, patchRequest, postRequest, postRequestEmptyResponse, putRequest } from './backend.utils';
import { ISubscriptionStatus } from './backend.interfaces.subscription-status';

export class BackendService {
  public static async getCustomer(): Promise<ICustomer> {
    const url = `${config.backendV2BaseUrl}/api/v1/customer?revision=2024-01`;
    return getRequest<ICustomer>(url);
  }

  public static async postRegistration(auth0Email: string): Promise<CustomerRegistrationHTTPStatusCode> {
    const url = `${config.backendV2BaseUrl}/api/v1/customer/register`;
    const response = await postRequest(url, { auth0Email });
    if (response.status === CustomerRegistrationHTTPStatusCode.CREATED) {
      return CustomerRegistrationHTTPStatusCode.CREATED;
    }
    if (response.status === CustomerRegistrationHTTPStatusCode.ALREADY_EXISTS) {
      return CustomerRegistrationHTTPStatusCode.ALREADY_EXISTS;
    }
    throw new Error('Unknown registration http status code');
  }

  public static async postRegistrationInfo(data: IRegistrationInfo): Promise<void> {
    const url = `${config.backendV2BaseUrl}/api/v1/customer/registration-info`;
    await postRequestEmptyResponse<IRegistrationInfo>(url, data);
  }

  public static async createStripeIntent(): Promise<IStripeIntentResponse> {
    const url = `${config.backendV2BaseUrl}/api/v1/customer/subscription/stripePaymentIntent`;
    const { data: stripeResponse } = await postRequest<IStripeIntentResponse>(url, {});
    return stripeResponse;
  }

  public static async createNewSubscription(data: IOrderSubscriptionPayload): Promise<ICustomer> {
    const url = `${config.backendV2BaseUrl}/api/v1/customer/subscription`;
    const { data: customer } = await postRequest<ICustomer, IOrderSubscriptionPayload>(url, data);
    return customer;
  }

  public static async createFreeSubscription(data: IFreeSubscriptionPayload): Promise<void> {
    const url = `${config.backendV2BaseUrl}/api/v1/customer/subscription/free`;
    await postRequest<void, IFreeSubscriptionPayload>(url, data);
  }

  public static async getStripeCouponInfo(couponId: string): Promise<IStripeCouponInfo> {
    const url = `${config.backendV2BaseUrl}/api/v1/couponValidate`;
    const { data: couponInfo } = await postRequest<IStripeCouponInfo, { stripeCouponId: string }>(url, {
      stripeCouponId: couponId,
    });
    return couponInfo;
  }

  public static async getSubscriptionStatus(): Promise<ISubscriptionStatus> {
    const url = `${config.backendV2BaseUrl}/api/v1/customer/subscription/status`;
    return getRequest<ISubscriptionStatus>(url);
  }

  public static async getOnboardingStatus(): Promise<IOnboardingStatus> {
    const url = `${config.backendV2BaseUrl}/api/v1/customer/onboarding/status`;
    return getRequest<IOnboardingStatus>(url);
  }

  public static async patchOnboardingStatus(id: OnboardingStatusPatchKey, isDone: boolean): Promise<number> {
    const url = `${config.backendV2BaseUrl}/api/v1/customer/onboarding/status`;
    const patchBody = { [id]: isDone };
    return patchRequest<IOnboardingPatchBody>(url, patchBody);
  }

  public static async getMedicalSpecialties(): Promise<IMedicalSpecialty[]> {
    const url = `${config.questionnairesV2BaseUrl}/medical-specialties`;
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return (await response.json()) as IMedicalSpecialty[];
    } catch (error) {
      console.error('Failed to fetch medical specialties:', error);
      throw error;
    }
  }

  public static async putFavoritesByMedicalSpecialty(medicalSpecialties: number[]): Promise<number> {
    const url = `${config.questionnairesV2BaseUrl}/favorites/by-medical-specialties`;
    return putRequest(url, { medicalSpecialties });
  }
}
