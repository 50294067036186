







































import { Component, Prop, Vue, Emit } from 'vue-property-decorator';

@Component
export default class IdanaSupportBar extends Vue {
  @Prop({ required: false, type: String, default: '' }) protected imageSrc!: string;
  @Prop({ required: false, type: String, default: '' }) protected title!: string;
  @Prop({ required: false, type: String, default: '' }) protected employee!: string;
  @Prop({ required: false, type: String, default: '' }) protected phone!: string;

  get phoneLink(): string {
    return `tel:${this.phone}`;
  }

  @Emit('hide')
  protected hide(): void {
    return;
  }
}
