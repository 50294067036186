




















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class OnboardingProgressBar extends Vue {
  @Prop({ required: true, type: Number, default: 0 }) currentActiveStep!: number;
  @Prop({ required: false, type: Number, default: 4 }) numOfSteps!: number;
  @Prop({ required: false, type: Number, default: 0 }) gapBetweenElements!: number;
  @Prop({ required: true, type: Number, default: 0 }) stepWidth!: number;

  private get stepShrinkRatio(): number {
    const stepsPerRow = Math.floor(window.innerWidth / this.stepWidth);
    const stepShrinkRatio = this.numOfSteps / Math.min(stepsPerRow, this.numOfSteps);

    return stepShrinkRatio;
  }

  private get progressAreaSize(): number {
    return this.stepWidth / this.stepShrinkRatio;
  }

  protected get steps(): number[] {
    const steps: number[] = [];

    for (let i = 1; i <= this.numOfSteps; i++) {
      steps.push(i);
    }

    return steps;
  }

  protected get barProgressStyle(): string {
    const offset = this.progressAreaSize / 4;
    let progress = `${this.stepPosition(this.currentActiveStep) - offset}px`;

    if (this.currentActiveStep === 0) progress = '0%';
    else if (this.currentActiveStep === this.numOfSteps) progress = '100%';

    return `width: ${progress}`;
  }

  private gapForStep(stepIndex: number): number {
    const numberOfGapsForStep = Math.floor(stepIndex / this.stepShrinkRatio);

    return this.gapBetweenElements * numberOfGapsForStep;
  }

  protected stepPosition(stepIndex: number): number {
    const position = this.progressAreaSize * stepIndex + this.gapForStep(stepIndex);
    const offset = this.progressAreaSize / 2;

    return position + offset;
  }

  protected stepPositionStyle(stepIndex: number): string {
    return `left: ${this.stepPosition(stepIndex)}px`;
  }

  protected isStepActive(stepIndex: number): boolean {
    return stepIndex < this.currentActiveStep;
  }
}
