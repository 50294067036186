


















































import { Vue, Component, Ref } from 'vue-property-decorator';

import store from '@/store';
import { BackendService } from '@/services/backend';
import { ICustomer, OnboardingStatusPatchKey } from '@/services/backend/backend.interfaces';
import { HttpRequestsHandler } from '@/shared/http-requests-handler';
import { onboardingSteps, Step } from './onboarding-steps.constants';

import OnboardingSingleStep from './OnboardingSingleStep.vue';
import OnboardingProgressBar from './OnboardingProgressBar.vue';

@Component({
  components: {
    OnboardingSingleStep,
    OnboardingProgressBar,
  },
})
export default class OnboardingSteps extends Vue {
  @Ref() protected readonly stepElements!: OnboardingSingleStep[];

  protected statusInitialized = false;
  protected initializationFailed = false;
  protected stepWidth = 0;

  private onboardingSteps = onboardingSteps;

  async mounted() {
    try {
      await this.initializeStatus();
      this.statusInitialized = true;
    } catch (error) {
      this.initializationFailed = true;
    }

    setTimeout(this.updateStepSize.bind(this), 100);
  }

  protected get isInitialized(): boolean {
    return this.statusInitialized && !!this.customer;
  }

  /**
   * Replaces `{{customerId}}` placeholder with actual customer id.
   * Necessary for dynamic urls, see IDA-3844
   */
  protected get onboardingStepsReplaced(): Step[] {
    const customerId = this.customer?.id || 0;

    return this.onboardingSteps.map((step) => ({
      ...step,
      linkToService: step.linkToService.replace('{{customerId}}', customerId.toString()),
    }));
  }

  private get customer(): ICustomer | null {
    return store.state.profile.customer;
  }

  protected get firstOpenStepIndex(): number {
    const upperBound = this.onboardingSteps.length;

    return this.onboardingSteps
      .map((step, index) => (step.isDone ? upperBound : index))
      .reduce((lowestIndex, currentIndex) => (currentIndex < lowestIndex ? currentIndex : lowestIndex));
  }

  private get furthestActiveStepNumber(): number {
    return this.onboardingSteps
      .map((step, index) => (step.isDone ? index + 1 : 0))
      .reduce((highestIndex, currentIndex) => (currentIndex > highestIndex ? currentIndex : highestIndex));
  }

  protected isStepLocked(index: number): boolean {
    return this.furthestActiveStepNumber < index;
  }

  protected async handleStepToggle(stepId: string): Promise<void> {
    const stepToToggle = this.onboardingSteps.find((step) => step.id === stepId);
    const patchKey = Object.values(OnboardingStatusPatchKey).find((key) => key === stepId + 'Done');

    if (!stepToToggle || !patchKey) return;

    const patchStatus = await BackendService.patchOnboardingStatus(patchKey, !stepToToggle.isDone);

    if (patchStatus === 204) {
      stepToToggle.isDone = !stepToToggle.isDone;
    }
  }

  protected updateStepSize() {
    if (this.stepElements) {
      this.stepWidth = this.stepElements[0].width;
    }
  }

  private async initializeStatus() {
    const statusData = await HttpRequestsHandler.handleRequestWithRetry(BackendService.getOnboardingStatus);

    this.onboardingSteps.forEach((step) => {
      if (statusData[step.id + 'Done']) {
        step.isDone = true;
      }
    });
  }
}
