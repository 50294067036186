import MobileDetect from 'mobile-detect';

function isOSX() {
  return /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
}

function isBrowserSafari() {
  return !!/Version\/([0-9._]+).*Safari/.exec(navigator.userAgent);
}

function isTouchDevice() {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');

  if (
    'ontouchstart' in window ||
    window.TouchEvent ||
    // It's the most general solution, so ignoring typescript error
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (window.DocumentTouch && document instanceof window.DocumentTouch)
  ) {
    return true;
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  const queryList = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');

  return window.matchMedia(queryList).matches;
}

function isIPadWithDesktopModeEnabled() {
  return isBrowserSafari() && isOSX() && isTouchDevice();
}

function isMobileDevice() {
  return (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile/i.test(navigator.userAgent) || isIPadWithDesktopModeEnabled()
  );
}

function getUserBrowserNameAndVersion(): { name: string; version: string } {
  const userAgent = navigator.userAgent;
  const separateVersionMatch = userAgent.match(/version\/(\d+)/i);
  let browserMatch = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

  if (/trident/i.test(browserMatch[1])) {
    const temporaryMatch = /\brv[ :]+(\d+)/g.exec(userAgent) || [];

    return { name: 'IE', version: temporaryMatch[1] || '' };
  }

  if (browserMatch[1] === 'Chrome') {
    let temporaryMatch;

    temporaryMatch = userAgent.match(/\bOPR\/(\d+)/);

    if (temporaryMatch) {
      return { name: 'Opera', version: temporaryMatch[1] };
    }

    // old Edge has full name in userAgent
    temporaryMatch = userAgent.match(/\bEdge\/(\d+)/);

    if (temporaryMatch) {
      return { name: 'Edge', version: temporaryMatch[1] };
    }

    // new Edge has short name in userAgent
    temporaryMatch = userAgent.match(/\bEdg\/(\d+)/);

    if (temporaryMatch) {
      return { name: 'Edge', version: temporaryMatch[1] };
    }
  }

  browserMatch = browserMatch[2] ? [browserMatch[1], browserMatch[2]] : [navigator.appName, navigator.appVersion, '-?'];

  if (separateVersionMatch !== null) {
    browserMatch.splice(1, 1, separateVersionMatch[1]);
  }

  return {
    name: browserMatch[0],
    version: browserMatch[1],
  };
}

function isMobileBrowserOutdated(): boolean {
  const md = new MobileDetect(window.navigator.userAgent);
  let browserName = md.userAgent();
  let browserVersion = md.version(browserName);

  if (!browserName || !browserVersion) {
    return false;
  }

  browserName = browserName.toLowerCase();
  browserVersion = Math.floor(Number(browserVersion));

  if (isOSX()) {
    if (browserName === 'safari' && browserVersion < 12) {
      return true;
    }
  } else {
    if (browserName === 'chrome' && browserVersion <= 74) {
      return true;
    }

    if (browserName === 'firefox' && browserVersion <= 60) {
      return true;
    }
  }

  return false;
}

function isDesktopBrowserOutdated(): boolean {
  const { name, version } = getUserBrowserNameAndVersion();
  const lowerCasedName = name.toLowerCase();

  if (lowerCasedName === 'safari' && !!version && Number(version) < 12) {
    return true;
  }

  if (lowerCasedName === 'chrome' && !!version && Number(version) <= 74) {
    return true;
  }

  if (lowerCasedName === 'firefox' && !!version && Number(version) <= 70) {
    return true;
  }

  if (lowerCasedName === 'opera' && !!version && Number(version) <= 60) {
    return true;
  }

  if (lowerCasedName === 'edge' && !!version && Number(version) < 80) {
    return true;
  }

  if (lowerCasedName === 'ie') {
    return true;
  }

  return false;
}

export function isBrowserOutdated(): boolean {
  if (isMobileDevice()) {
    return isMobileBrowserOutdated();
  }

  return isDesktopBrowserOutdated();
}
