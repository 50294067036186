export enum SubscriptionStatus {
  NO_SUBSCRIPTION = 'no-subscription',
  FREE = 'free',
  FREE_EXPIRED = 'free-expired',
  ACTIVE = 'active',
  DISABLED = 'disabled',
  EXPIRED = 'expired',
}

interface ISubscriptionStatusValid {
  status: SubscriptionStatus.ACTIVE | SubscriptionStatus.FREE;
  daysLeft: number;
}

interface ISubscriptionStatusInvalid {
  status:
    | SubscriptionStatus.NO_SUBSCRIPTION
    | SubscriptionStatus.EXPIRED
    | SubscriptionStatus.FREE_EXPIRED
    | SubscriptionStatus.DISABLED;
  daysLeft: 0;
}

/** This is the interface of the subscription status route's response  */
export type ISubscriptionStatus = ISubscriptionStatusValid | ISubscriptionStatusInvalid;
