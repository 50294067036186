













































































import { Component, Vue } from 'vue-property-decorator';

import store from '@/store';
import { BackendService, IBillingAddress } from '@/services/backend';
import { HttpRequestsHandler } from '@/shared/http-requests-handler';
import { getLocalizedDateString } from './utils/formatters.utils';

@Component
export default class OrderSubscriptionFreePlanSummary extends Vue {
  protected isLoading = false;

  protected get isMediumMobileDevice(): boolean {
    return store.getters['layoutModule/isMobileDeviceWidthExcludingLarge'];
  }

  protected get currentDate(): string {
    return getLocalizedDateString(new Date());
  }

  protected get institutionName(): string {
    return store.state.order.institutionName;
  }

  protected get streetAndHouseNumber(): string {
    return store.state.order.address.streetAndHouseNumber;
  }

  protected get city(): string {
    return store.state.order.address.city;
  }

  protected get zipCode(): string {
    return store.state.order.address.zipCode;
  }

  protected get email(): string {
    return store.state.order.email;
  }

  protected async createFreeSubscription(): Promise<void> {
    this.isLoading = true;

    const billingAddress: IBillingAddress = {
      billingInstitution: this.institutionName,
      billingEmail: this.email,
      streetAndHouseNumber: this.streetAndHouseNumber,
      city: this.city,
      zipCode: this.zipCode,
      countryCode: 'DE',
    };

    try {
      await HttpRequestsHandler.handleRequestWithRetry(BackendService.createFreeSubscription, { billingAddress });
      await this.$router.push({ path: '/order-subscription/success' });
      this.isLoading = false;
    } catch (error) {
      console.error('Subscription failed', error);
      this.isLoading = false;
    }
  }
}
