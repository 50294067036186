























































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import { formatMoneyAmount } from '../utils/format-money-amount.filter';
import { IIdanaProductBenefitsItem } from './idana-product-card.interfaces';

@Component({
  filters: { formatMoneyAmount },
})
export default class IdanaProductCard extends Vue {
  @Prop({ required: true, type: Array, default: () => [] }) protected benefitsList!: IIdanaProductBenefitsItem[];
  @Prop({ required: true, type: String, default: '' }) protected title!: string;
  @Prop({ required: false, type: String, default: '' }) protected description!: string;
  @Prop({ required: true, type: Number, default: 0 }) protected price!: number;
  @Prop({ required: false, type: Number, default: 0 }) protected priceWithDiscount!: number;
  @Prop({ required: false, type: String, default: '' }) protected priceHint!: string;
  @Prop({ required: false, type: String, default: '' }) protected productHint!: string;
  @Prop({ required: false, type: Boolean, default: false }) protected isSelected!: boolean;
  @Prop({ required: false, type: Boolean, default: false }) protected isHighlighted!: boolean;

  @Emit('selected')
  protected setSelected() {
    return;
  }
}
