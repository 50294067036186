import sodium from 'libsodium-wrappers';
import { Buffer } from 'buffer';

import { IArgonHashConfig, IPinArgonHash } from './crypto.interfaces';

export class CryptoService {
  private static async getNewHashConfig(): Promise<IArgonHashConfig> {
    await sodium.ready;

    const DEFAULT_CONFIG: Omit<IArgonHashConfig, 'saltBase64'> = {
      algorithmLibsodiumConst: sodium.crypto_pwhash_ALG_ARGON2ID13,
      memory: sodium.crypto_pwhash_MEMLIMIT_INTERACTIVE,
      iterations: sodium.crypto_pwhash_OPSLIMIT_INTERACTIVE,
      keyLength: 32,
    };

    const salt = sodium.randombytes_buf(sodium.crypto_pwhash_SALTBYTES, 'uint8array');

    return {
      ...DEFAULT_CONFIG,
      saltBase64: Buffer.from(salt).toString('base64'),
    };
  }

  /** Hashes a pin with argon2 algorithm, either randomly or deterministic when config is provided.
   * @param input - string input to be hashed
   * @param argonHashConfig - optional configuration for argon2 algorithm to produce deterministic hash
   * @returns PinArgonHash with hex encoded hash and configuration used to generate the hash.
   */
  public static async createArgonPinHash(input: string, argonHashConfig?: IArgonHashConfig): Promise<IPinArgonHash> {
    await sodium.ready;

    const config = argonHashConfig || (await CryptoService.getNewHashConfig());

    const salt = new Uint8Array(Buffer.from(config.saltBase64, 'base64'));

    const res = sodium.crypto_pwhash(
      config.keyLength,
      input,
      salt,
      config.iterations,
      config.memory,
      config.algorithmLibsodiumConst,
    );

    const hashHex = Buffer.from(res).toString('hex');

    return {
      hashHex,
      config,
    };
  }

  /** Encrypts a string with base64 encoded public key. Returns encrypted data encoded in base64.
   *
   * @param data - data string to be encrypted
   * @param publicKey - public key used for asymmetric encryption
   * @returns encrypted data string encoded in base64
   */
  public static async encryptWithPublicKey(data: string, publicKey: string): Promise<string> {
    await sodium.ready;

    const publicKeyBuffer = Buffer.from(publicKey, 'base64');
    const dataEncrypted = sodium.crypto_box_seal(data, new Uint8Array(publicKeyBuffer), 'uint8array');

    return Buffer.from(dataEncrypted).toString('base64');
  }
}
