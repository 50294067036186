



















































































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class OnboardingSingleStep extends Vue {
  @Prop({ required: true, type: String, default: '' }) protected linkToService!: string;
  @Prop({ required: true, type: String, default: '' }) protected linkText!: string;
  @Prop({ required: true, type: String, default: '' }) protected linkDoneText!: string;
  @Prop({ required: true, type: String, default: '' }) protected headline!: string;
  @Prop({ required: true, type: String, default: '' }) protected text!: string;
  @Prop({ required: false, type: Boolean, default: false }) protected isDone!: boolean;
  @Prop({ required: false, type: Boolean, default: false }) protected isLocked!: boolean;
  @Prop({ required: true, type: String, default: '' }) protected icon!: string;
  @Prop({ required: true, type: String, default: '' }) protected imagePath!: string;
  @Prop({ required: true, type: String }) protected id!: string;

  private cardWidth = 0;

  mounted() {
    setTimeout(() => {
      this.updateSize();
    }, 0);
  }

  public get width(): number {
    return this.cardWidth;
  }

  protected get imageCheckBoxButtonText(): string {
    return this.isDone ? 'erledigt' : 'abhaken';
  }

  protected get buttonText(): string {
    return this.isDone ? this.linkDoneText : this.linkText;
  }

  protected get isSmall(): boolean {
    return this.$vuetify.breakpoint.name === 'xs';
  }

  protected toggle(e: Event): void {
    e.preventDefault();

    this.$emit('toggle', this.id);
  }

  protected updateSize(): void {
    this.cardWidth = this.$el.getBoundingClientRect().width;
  }
}
