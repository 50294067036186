import { SupportedPaymentMethod } from '@/store/modules';

export function formatPaymentMethod(method: SupportedPaymentMethod | null): string {
  switch (method) {
    case 'card':
      return 'Kreditkarte';
    case 'sepa_debit':
      return 'SEPA-Lastschrift';
    case null:
      return 'Nicht festgelegt';
    default:
      return 'Festgelegt';
  }
}

export function formatNumber(numberValue: number, minFractionDigits = 0): string {
  const formatter = new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: minFractionDigits,
    maximumFractionDigits: 2,
  });
  return formatter.format(numberValue);
}

export function getLocalizedDateString(date: Date): string {
  return new Date(date).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' });
}
