



























import { Component, Vue } from 'vue-property-decorator';

@Component
export default class AppFooter extends Vue {
  protected readonly impressumUrl = 'https://idana.com/impressum/';
  protected readonly privacyPolicyUrl = 'https://idana.com/DSE-Idana-Praxissoftware.pdf';
}
