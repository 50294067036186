

















































import { IdanaSignature } from '@tomesgmbh/idana-signature';
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

@Component({})
export default class SignatureDrawArea extends Vue {
  @Prop({ type: Boolean, required: false, default: false }) isFullscreen!: boolean;
  @Prop({ type: Number, required: false, default: 0 }) bottomPadding!: number;

  private readonly ASPECT_RATIO = 16 / 9;
  private readonly IMG_WIDTH = 1000;
  private readonly IMG_HEIGHT = this.IMG_WIDTH / this.ASPECT_RATIO;

  private windowHeight: number = window.innerHeight;

  @Ref('signatureCanvas') signatureCanvas!: HTMLCanvasElement;
  protected idanaSignature: IdanaSignature | null = null;

  mounted(): void {
    this.idanaSignature = new IdanaSignature(this.signatureCanvas);
    this.resetSignatureCanvas();
  }

  protected get canvasContainerStyle(): Partial<CSSStyleDeclaration> {
    if (this.isFullscreen) {
      const height = `${this.windowHeight}px`;

      return {
        width: `calc((${height} - ${this.bottomPadding + 8}px) * ${this.ASPECT_RATIO})`, // 8px for top
        height: `calc(${height} - ${this.bottomPadding + 8}px)`, // 8px for top
        top: `8px`,
      };
    } else {
      return {
        paddingTop: `${100 / this.ASPECT_RATIO}%`,
      };
    }
  }

  protected get showSignatureHint(): boolean {
    return !this.isValid;
  }

  private get isValid(): boolean {
    return this.idanaSignature !== null && !this.idanaSignature.isEmpty();
  }

  protected resetSignatureCanvas(): void {
    if (this.signatureCanvas) {
      const scaleRatio = this.IMG_WIDTH / this.signatureCanvas.clientWidth;

      this.signatureCanvas.width = this.IMG_WIDTH;
      this.signatureCanvas.height = this.IMG_HEIGHT;
      this.signatureCanvas.getContext('2d')?.scale(scaleRatio, scaleRatio);

      this.idanaSignature?.clear();
    }
  }

  public init(): void {
    this.idanaSignature = new IdanaSignature(this.signatureCanvas);
    this.updateCanvasSize();
  }

  public updateCanvasSize(): void {
    // (re)trigger container size calculation
    this.windowHeight = window.innerHeight;

    setTimeout(this.resetSignatureCanvas, 500);
  }

  public clearSignature(): void {
    this.idanaSignature?.clear();
  }

  public getImageIfValid(): string | null {
    if (this.isValid && this.idanaSignature) return this.idanaSignature.toDataURL();
    else return null;
  }
}
