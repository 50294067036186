




































import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
import { IApiKeyResponseData } from './api-keys.interfaces';

@Component({
  components: {},
})
export default class ApiKeysDashboardTable extends Vue {
  @Prop({ type: Array, required: true }) apiKeys!: IApiKeyResponseData[];

  protected headers = [
    { text: 'Name', sortable: false, value: 'name', width: '40%' },
    { text: 'API-Schlüssel', sortable: false, value: 'apiKey', width: '250px' },
    { text: 'Aktionen', sortable: false, value: 'actions', width: '20%', align: 'center' },
  ];

  protected get elevation(): string {
    if (this.$vuetify.breakpoint.smAndUp) {
      return 'elevation-4';
    } else {
      return 'elevation-0';
    }
  }

  @Emit('delete-api-key')
  protected deleteApiKey(item: IApiKeyResponseData): IApiKeyResponseData {
    return item;
  }
}
