



































import { Component, Vue } from 'vue-property-decorator';

import { AuthServiceSingleton } from '@/services/auth/auth.service';
import idanaIcon from '@/assets/idana-icon.svg';

import AppFooter from '@/components/AppFooter.vue';

@Component({
  components: { AppFooter },
})
export default class LoginPage extends Vue {
  protected idanaIcon = idanaIcon;

  protected login(): void {
    AuthServiceSingleton.login();
  }

  protected goToRegistration(): void {
    this.$router.push({ path: '/start' });
  }
}
