import { BackendService } from '@/services/backend';
import { HttpRequestsHandler } from '@/shared/http-requests-handler';
import { orderStateDefault } from '@/store/modules/order/order.state';
import {
  filterSecureDataFromStorage,
  isCouponProductPlanCorrect,
  restoreMissingStateValues,
  restoreStateWithDefaults,
} from '@/store/modules/order/order.utils';
import { ActionTree } from 'vuex';

import { IOrderState } from './order.interfaces';
import { IAppState } from '@/store';
import { LocalStorage, StorageKeys } from '@/services/local-storage';

export const orderActions: ActionTree<IOrderState, IAppState> = {
  enableFreeAccountMode(store) {
    LocalStorage.setString(StorageKeys.FREE_ACCOUNT_ACTIVE, 'true');
    store.commit('isFreeAccount', true);
  },

  disableFreeAccountMode(store) {
    LocalStorage.removeItem(StorageKeys.FREE_ACCOUNT_ACTIVE);
    store.commit('isFreeAccount', false);
  },

  saveStateToLocalStorage(store) {
    LocalStorage.setObject(StorageKeys.STATE_ORDER, filterSecureDataFromStorage(store.state));
  },

  restoreFromLocalStorage(store): void {
    let restoredState: IOrderState;

    const loadedState = LocalStorage.getObject<IOrderState>(StorageKeys.STATE_ORDER);
    if (loadedState) {
      restoredState = restoreStateWithDefaults(orderStateDefault, loadedState);
      store.commit('updateWholeState', restoredState);
    } else {
      restoredState = { ...orderStateDefault };
    }

    restoreMissingStateValues(restoredState, store);
  },

  removeOrderStateFromStorage() {
    LocalStorage.removeItem(StorageKeys.STATE_ORDER);
  },

  removeOrderSpecificInfoFromStorageAfterOrder() {
    LocalStorage.removeItem(StorageKeys.INSTITUTION_NAME);
    LocalStorage.removeItem(StorageKeys.MEDICAL_SPECIALTIES);
    LocalStorage.removeItem(StorageKeys.HUBSPOT_DEAL_ID);
    LocalStorage.removeItem(StorageKeys.COUPON_ID);
    LocalStorage.removeItem(StorageKeys.FREE_ACCOUNT_ACTIVE);
  },

  async fetchCouponInfo(store): Promise<void> {
    const couponId = store.state.idanaSubscription.stripeCouponId;

    try {
      const couponInfo = await HttpRequestsHandler.handleRequestWithRetry(BackendService.getStripeCouponInfo, couponId);

      if (!couponInfo.valid || !isCouponProductPlanCorrect(couponInfo.idanaSubscriptionPlan)) {
        store.commit('stripeCouponId', '');
      } else {
        store.commit('stripeCouponInfo', couponInfo);
      }
    } catch {
      store.commit('stripeCouponId', '');
    }
  },
};
