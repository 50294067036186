






















import store from '@/store';
import { Component, Vue } from 'vue-property-decorator';

import { IIdanaSupportBarConfig } from '@/shared/idana-support-bar';
import { IRoutingStepperConfigItem, signUpStepperConfig } from '@/shared/routing-stepper';

import RoutingStepper from '@/shared/routing-stepper/RoutingStepper.vue';
import SplitPageLayout from '@/shared/split-page-layout/SplitPageLayout.vue';
import AppFooter from '@/components/AppFooter.vue';

@Component({
  components: { RoutingStepper, SplitPageLayout, AppFooter },
})
export default class SignUpRootPage extends Vue {
  protected supportInfo: IIdanaSupportBarConfig = {
    title: 'Sie haben Fragen?',
    phone: '0761 60067841',
  };

  protected stepperValues: IRoutingStepperConfigItem[] = signUpStepperConfig;

  protected get isFreeAccountMode(): boolean {
    return store.state.order.isFreeAccount;
  }

  protected get sideNavText(): string {
    return this.isFreeAccountMode
      ? 'Legen Sie zuerst ein Idana-Konto an.'
      : 'Legen Sie zuerst ein Idana-Konto an. Anschließend können Sie Idana bestellen.';
  }
}
