







































import { Component, Vue } from 'vue-property-decorator';

import idanaIcon from '@/assets/idana-icon.svg';
import { AuthServiceSingleton } from '@/services/auth/auth.service';

import AppFooter from '@/components/AppFooter.vue';

@Component({
  components: { AppFooter },
})
export default class FreeRegistrationPage extends Vue {
  protected idanaIcon = idanaIcon;

  protected login(): void {
    AuthServiceSingleton.login();
  }
}
