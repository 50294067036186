import Vue from 'vue';
import Vuex from 'vuex';

import {
  httpFailureDialogModule,
  IHttpFailureDialogState,
  ILayoutState,
  IOrderState,
  IProfileState,
  layoutModule,
  orderModule,
  profileModule,
} from './modules';

Vue.use(Vuex);

export interface IAppState {
  order: IOrderState;
  profile: IProfileState;
  httpFailureDialog: IHttpFailureDialogState;
  layout: ILayoutState;
}

const store = new Vuex.Store<IAppState>({
  strict: true,
  modules: {
    order: orderModule,
    profile: profileModule,
    httpFailureDialog: httpFailureDialogModule,
    layoutModule: layoutModule,
  },
});

export default store;
