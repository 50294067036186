

























import { Vue, Component, Ref } from 'vue-property-decorator';

import avatarCaterina from '@/assets/avatar-caterina-linder.png';
import AppHeader from '@/components/AppHeader.vue';
import AppFooter from '@/components/AppFooter.vue';
import SignatureDashboard from '@/modules/account/signatures/SignatureDashboard.vue';
import CreateSignatureDialog from '@/modules/account/signatures/CreateSignatureDialog.vue';
import EditSignatureDialog from '@/modules/account/signatures/EditSignatureDialog.vue';
import DeleteSignatureDialog from '@/modules/account/signatures/DeleteSignatureDialog.vue';
import IdanaSupportBar from '@/shared/idana-support-bar/IdanaSupportBar.vue';

import store from '@/store';
import { IIdanaSupportBarConfig } from '@/shared/idana-support-bar';
import { ISignatureResponseData } from '@/modules/account/signatures/signature.interfaces';

@Component({
  components: {
    AppHeader,
    AppFooter,
    IdanaSupportBar,
    SignatureDashboard,
    CreateSignatureDialog,
    EditSignatureDialog,
    DeleteSignatureDialog,
  },
})
export default class SignaturePage extends Vue {
  protected showSignatureDashboard = true;

  @Ref('signatureDashboard') protected signatureDashboard!: SignatureDashboard;
  @Ref('createSignatureDialog') protected createSignatureDialog!: CreateSignatureDialog;
  @Ref('editSignatureDialog') protected editSignatureDialog!: EditSignatureDialog;
  @Ref('deleteSignatureDialog') deleteSignatureDialog!: DeleteSignatureDialog;

  beforeMount() {
    store.dispatch('profile/fetchCustomer');
  }

  protected get supportBarConfig(): IIdanaSupportBarConfig | null {
    return {
      imageSrc: avatarCaterina,
      employee: 'Caterina Linder',
      phone: '0761 6006784 38',
    };
  }

  protected async openCreateSignatureDialog() {
    const isCreated = await this.createSignatureDialog.show();
    if (isCreated) await this.signatureDashboard.updateSignaturesList();
  }

  protected async openEditSignatureDialog(signature: ISignatureResponseData) {
    const isUpdated = await this.editSignatureDialog.show(signature);
    if (isUpdated) await this.signatureDashboard.updateSignaturesList();
  }

  protected async openDeleteSignatureDialog(signatureId: number) {
    const isDeleted = await this.deleteSignatureDialog.showDeleteDialog(signatureId);
    if (isDeleted) await this.signatureDashboard.updateSignaturesList();
  }
}
