import { IAppState } from '@/store';
import { ILayoutState } from '@/store/modules';
import { layoutGetters } from '@/store/modules/layout/layout.getters';
import { layoutMutations } from '@/store/modules/layout/layout.mutations';
import { layoutStateDefault } from '@/store/modules/layout/layout.state';
import { Module } from 'vuex';

export const layoutModule: Module<ILayoutState, IAppState> = {
  namespaced: true,
  state: JSON.parse(JSON.stringify(layoutStateDefault)) as ILayoutState,
  getters: layoutGetters,
  mutations: layoutMutations,
};
