









































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IDeleteApiKeyDialog } from './api-keys.interfaces';

import { ApiKeyDialogCancelledError } from './api-keys.errors';

@Component({})
export default class DeleteApiKeyDialog extends Vue implements IDeleteApiKeyDialog {
  @Prop({ type: Boolean, default: false }) fullscreen!: boolean;

  protected isVisible = false;
  protected isLoading = false;

  private resolveAction: (() => void) | null = null;
  private rejectAction: ((error: Error) => void) | null = null;

  public async show(): Promise<void> {
    this.isVisible = true;

    return new Promise((resolve, reject) => {
      this.resolveAction = resolve;
      this.rejectAction = reject;
    });
  }

  public setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  public hide(): void {
    this.isVisible = false;
  }

  protected closeDialog(): void {
    if (!this.rejectAction) return;
    this.rejectAction(new ApiKeyDialogCancelledError());
    this.isVisible = false;
  }

  @Watch('isVisible')
  protected onIsVisibleChanged(isVisible: boolean) {
    if (!isVisible) this.resetDialog();
  }

  protected confirmDeletion() {
    if (!this.resolveAction || !this.rejectAction) {
      this.hide();
      return;
    }

    this.resolveAction();
  }

  private resetDialog(): void {
    this.isLoading = false;

    this.resolveAction = null;
    this.rejectAction = null;
  }
}
