








































import { PropType } from 'vue';
import { Vue, Prop, Component, Emit } from 'vue-property-decorator';
import { ISignatureResponseData, ISigner } from './signature.interfaces';

@Component({})
export default class SignaturePreview extends Vue {
  @Prop({ type: Object as PropType<ISignatureResponseData>, required: true }) signatureData!: ISignatureResponseData;

  protected get lastUpdated(): string {
    return new Date(this.signatureData.lastUpdated).toLocaleDateString('de');
  }
  protected get signer(): ISigner {
    return this.signatureData.signer;
  }

  @Emit('delete')
  protected deleteSignature(): number {
    return this.signatureData.id;
  }

  @Emit('edit')
  protected editSignature(): ISignatureResponseData {
    return this.signatureData;
  }
}
