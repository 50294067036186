import { GetterTree } from 'vuex';

import { SubscriptionStatus } from '@/services/backend';
import { IAppState } from '@/store';
import { IProfileState } from '@/store/modules';

export const profileGetters: GetterTree<IProfileState, IAppState> = {
  isSubscriptionAccount(profileState: IProfileState): boolean {
    if (profileState.subscriptionStatus === null) return false;

    const status = profileState.subscriptionStatus.status;

    return (
      status === SubscriptionStatus.ACTIVE ||
      status === SubscriptionStatus.EXPIRED ||
      status === SubscriptionStatus.DISABLED
    );
  },

  isFreeAccount(profileState: IProfileState): boolean {
    if (profileState.subscriptionStatus === null) return false;

    const status = profileState.subscriptionStatus.status;

    return status === SubscriptionStatus.FREE || status === SubscriptionStatus.FREE_EXPIRED;
  },
};
