


































import { Component, Vue, Watch } from 'vue-property-decorator';

import store from '@/store';

@Component
export default class OrderSubscriptionLicences extends Vue {
  protected numberOfLicences = store.state.order.idanaSubscription.numberOfLicences;

  protected get isMediumMobileDevice(): boolean {
    return store.getters['layoutModule/isMobileDeviceWidthExcludingLarge'];
  }

  private numberOfLicensesTimeout: number | null = null;

  @Watch('numberOfLicences')
  protected onNumberOfLicencesChanged(newNumberOfLicencesString: string, oldNumberOfLicencesString: string) {
    if (newNumberOfLicencesString === oldNumberOfLicencesString) return;

    if (this.numberOfLicensesTimeout) {
      window.clearTimeout(this.numberOfLicensesTimeout);
    }

    this.numberOfLicensesTimeout = window.setTimeout(() => {
      this.handleNumberOfLicensesUpdate(newNumberOfLicencesString);
    }, 200);
  }

  protected get isValid(): boolean {
    return store.state.order.idanaSubscription.numberOfLicences >= 1;
  }

  protected beforeDestroy(): void {
    if (this.isValid) {
      store.dispatch('order/saveStateToLocalStorage');
    }
  }

  private handleNumberOfLicensesUpdate(newNumberOfLicencesString: string): void {
    const isNotANumber = isNaN(Number(newNumberOfLicencesString));
    const isLessThanZero = Number(newNumberOfLicencesString) < 0;

    this.numberOfLicences = isNotANumber || isLessThanZero ? 0 : Math.floor(Number(newNumberOfLicencesString));
    store.commit('order/numberOfLicences', this.numberOfLicences);
  }
}
