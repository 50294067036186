






































































































































import { Component, Vue } from 'vue-property-decorator';

import store from '@/store';
import { BackendService, IBillingAddress, IOrderSubscriptionPayload } from '@/services/backend';
import { IdanaOnboardingPlan, IdanaSubscriptionPlan } from '@/shared/interfaces';
import { HttpRequestsHandler } from '@/shared/http-requests-handler';
import { formatMoneyAmount } from './utils/format-money-amount.filter';
import {
  getIdanaSubscriptionPlanName,
  getIdanaSubscriptionPlanPrice,
} from './idana-product-card/idana-product-card.utils';
import { formatPaymentMethod, getLocalizedDateString } from './utils/formatters.utils';

import SummarySubscriptionCostsSection from './components/SummarySubscriptionCostsSection.vue';

@Component({
  components: { SummarySubscriptionCostsSection },
  filters: { formatMoneyAmount },
})
export default class OrderSubscriptionSummary extends Vue {
  protected isLoading = false;

  protected get isMediumMobileDevice(): boolean {
    return store.getters['layoutModule/isMobileDeviceWidthExcludingLarge'];
  }

  protected get currentDate(): string {
    return getLocalizedDateString(new Date());
  }

  protected get onboardingFee(): number {
    return store.state.order.onboarding.prices[this.selectedOnboardingPlan];
  }

  protected get numberOfLicences(): number {
    return store.state.order.idanaSubscription.numberOfLicences;
  }

  protected get selectedSubscriptionPlan(): IdanaSubscriptionPlan | null {
    return store.state.order.idanaSubscription.selectedSubscriptionPlan;
  }

  protected get subscriptionName(): string {
    return getIdanaSubscriptionPlanName(store.state.order.idanaSubscription.selectedSubscriptionPlan);
  }

  protected get isMonthlyPlan(): boolean {
    return store.getters['order/isMonthlyPlanSelected'];
  }

  protected get isPlanWithDiscountSelected(): boolean {
    return store.state.order.idanaSubscription.isPlanWithDiscountSelected;
  }

  protected get discountCouponId(): string {
    return store.state.order.idanaSubscription.stripeCouponId;
  }

  protected get discountPercent(): number {
    return store.getters['order/subscriptionDiscountPercent'];
  }

  protected get discountMonthsCount(): number {
    return store.getters['order/specialPlanDiscountMonthsDuration'];
  }

  protected get idanaSubscriptionPlanPrice(): number {
    return getIdanaSubscriptionPlanPrice(
      store.state.order,
      store.state.order.idanaSubscription.selectedSubscriptionPlan,
    );
  }

  protected get idanaSubscriptionPlanPriceWithDiscount(): number {
    return this.isPlanWithDiscountSelected ? store.getters['order/specialPlanPriceWithDiscount'] : 0;
  }

  protected get totalCostWithTaxesAndDiscount(): number {
    return store.getters['order/totalPlanCostWithTaxesAndDiscount'];
  }

  protected get totalPlanCostWithTaxes(): number {
    return store.getters['order/totalPlanCostWithTaxes'];
  }

  protected get isIdanaEditorSelected(): boolean {
    return store.state.order.idanaEditor.isEditorSelected;
  }

  protected get idanaEditorCost(): number {
    return store.state.order.idanaEditor.price;
  }

  protected get idanaEditorCostWithTaxes(): number {
    return store.state.order.idanaEditor.price * 1.19;
  }

  protected get idanaEditorCostTaxes(): number {
    return store.state.order.idanaEditor.price * 0.19;
  }

  protected get institutionName(): string {
    return store.state.order.institutionName;
  }

  protected get email(): string {
    return store.state.order.email;
  }

  protected get streetAndHouseNumber(): string {
    return store.state.order.address.streetAndHouseNumber;
  }

  protected get city(): string {
    return store.state.order.address.city;
  }

  protected get zipCode(): string {
    return store.state.order.address.zipCode;
  }

  protected get paymentMethod(): string {
    return formatPaymentMethod(store.state.order.paymentDetails.paymentMethod);
  }

  protected get stripePaymentMethodId(): string | null {
    return store.state.order.paymentDetails.stripePaymentMethodId;
  }

  get selectedOnboardingPlan(): IdanaOnboardingPlan {
    return store.state.order.onboarding.selectedPlan;
  }

  get onboardingPlanName(): string {
    switch (this.selectedOnboardingPlan) {
      case IdanaOnboardingPlan.IDANA_ONBOARDING_BASIS:
        return 'Basis';
      case IdanaOnboardingPlan.IDANA_ONBOARDING_BASIS_PLUS:
        return 'Basis Plus';
      default:
        return '';
    }
  }

  protected async createNewSubscription() {
    if (this.selectedSubscriptionPlan === null) throw new Error('No Idana Subscription selected.');
    if (this.stripePaymentMethodId === null) throw new Error('Stripe Payment Method Id is undefined.');

    this.isLoading = true;

    const billingAddress: IBillingAddress = {
      billingInstitution: this.institutionName,
      billingEmail: this.email,
      streetAndHouseNumber: this.streetAndHouseNumber,
      city: this.city,
      zipCode: this.zipCode,
      countryCode: 'DE',
    };

    const subscriptionRequest: IOrderSubscriptionPayload = {
      billingAddress,
      selectedIdanaSubscription: this.selectedSubscriptionPlan,
      numberOfLicences: this.numberOfLicences,
      idanaEditorSelected: this.isIdanaEditorSelected,
      stripePaymentMethodId: this.stripePaymentMethodId,
      onboardingTier: this.selectedOnboardingPlan,
    };

    if (this.isPlanWithDiscountSelected) {
      subscriptionRequest.stripeCouponId = this.discountCouponId;
    }

    try {
      const customer = await HttpRequestsHandler.handleRequestWithRetry(
        BackendService.createNewSubscription,
        subscriptionRequest,
      );

      if (!customer.stripeCustomerId) console.error('No Stripe Customer Id returned.');

      this.$router.push({ path: '/order-subscription/success' });
      this.isLoading = false;
    } catch (error) {
      console.error('Subscription failed', error);
      this.isLoading = false;
    }
  }
}
