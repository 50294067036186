





































import { ICommonInfoDialogButton } from '@/shared/common-info-dialog';
import store from '@/store';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CommonInfoDialog extends Vue {
  @Prop({ required: false, default: 400, type: Number }) protected maxWidth!: number;
  @Prop({ required: true, type: Boolean, default: false }) protected isVisible!: string;
  @Prop({ required: true, type: String, default: '' }) protected title!: string;
  @Prop({ required: true, type: String, default: '' }) protected text!: string;
  @Prop({ required: true, type: Array, default: () => [] }) protected buttons!: ICommonInfoDialogButton[];

  @Emit('close')
  protected closeDialog() {
    return;
  }

  protected get isMobile(): boolean {
    return store.getters['layoutModule/isMobileDeviceWidthExcludingLarge'];
  }

  protected onButtonClick(customAction: () => void): void {
    if (customAction) customAction();
    this.closeDialog();
  }
}
