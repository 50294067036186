































import store from '@/store';
import { IHttpFailureDialogConfig } from '@/store/modules';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class HttpRequestFailureDialog extends Vue {
  protected get dialogConfig(): IHttpFailureDialogConfig {
    return store.getters['httpFailureDialog/dialogConfig'];
  }

  protected onButtonClick(buttonAction: () => void): void {
    buttonAction();
    store.dispatch('httpFailureDialog/hideDialog');
  }
}
