
























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class IdanaProductCardBenefitsListItem extends Vue {
  @Prop({ required: true, type: String }) protected item!: string;
}
