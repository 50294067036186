import { HttpRequestsHandler } from '@/shared/http-requests-handler';
import {
  loadStripe,
  Stripe,
  StripeElements,
  StripePaymentElement,
  StripePaymentElementChangeEvent,
} from '@stripe/stripe-js';

import config from '@/config';
import { BackendService } from '@/services/backend';

class StripeService {
  private _stripe: Stripe | undefined;
  private _stripeElements: StripeElements | undefined;
  private _element: StripePaymentElement | undefined;
  private _clientSecret: string | undefined;
  private _isInputValid = false;

  private get stripe(): Stripe {
    if (!this._stripe) throw new Error('StripeService is not initialized.');
    return this._stripe;
  }

  private get element(): StripePaymentElement {
    if (!this._element) throw new Error('StripeService is not initialized.');
    return this._element;
  }

  public get isInitialized() {
    if (!this._stripe) return false;
    return true;
  }

  public get isReady() {
    if (!this._element) return false;
    return true;
  }

  public get isValid() {
    if (!this._stripe) return false;
    return this._isInputValid;
  }

  public async init(): Promise<void> {
    const stripe = await loadStripe(config.stripeConfig.publicKey);

    if (!stripe) throw new Error('Loading Stripe SDK Failed!');

    this._stripe = stripe;
  }

  public async initPaymentElement() {
    await this.setupPaymentIntent();
    this._stripeElements = this.stripe.elements({ clientSecret: this._clientSecret });
    this._element = this._stripeElements.create('payment', {
      paymentMethodOrder: ['sepa_debit', 'card'],
      wallets: {
        applePay: 'never',
        googlePay: 'never',
      },
    });
  }

  public mountPaymentElement(
    domElementSelector = '#payment-element',
    validationCallback: (event: StripePaymentElementChangeEvent) => void,
  ): Promise<void> {
    return new Promise((resolve) => {
      this.element.mount(domElementSelector);
      this.element.on('change', validationCallback);
      this.element.on('ready', () => resolve());
    });
  }

  public async confirmPaymentMethod(): Promise<string> {
    if (!this._stripeElements) throw new Error('Stripe Client Secret is undefined');
    if (!this._clientSecret) throw new Error('Stripe Elements is undefined');

    const result = await this.stripe.confirmSetup({
      elements: this._stripeElements,
      redirect: 'if_required',
    });

    if (result.error) {
      throw new Error(result.error.message);
    }

    if (!result.setupIntent.payment_method) {
      throw new Error('Payment Method Id is undefined');
    }

    return result.setupIntent.payment_method as string; // only object if `stripe.confirmSetup` is called with `expand`
  }

  private async setupPaymentIntent() {
    const stripeIntent = await HttpRequestsHandler.handleRequestWithRetry(BackendService.createStripeIntent);

    if (!stripeIntent || !stripeIntent.clientSecret) {
      throw new Error('Creating Stripe Intent Failed!');
    }

    this._clientSecret = stripeIntent.clientSecret;
  }
}

export const StripeServiceSingleton = new StripeService();
