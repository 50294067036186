import { SentryServiceSingleton } from '@/services/sentry/sentry.service';
import { ApiKeyDialogCancelledError } from './api-keys.errors';
import {
  IApiKeyResponseData,
  IApiKeysDashboard,
  ICreateApiKeyDialog,
  IDeleteApiKeyDialog,
  IShowCreatedApiKeyDialog,
} from './api-keys.interfaces';

export interface IApiKeysHttpService {
  getAllApiKeys(): Promise<IApiKeyResponseData[]>;
  createApiKey(apiKeyName: string): Promise<string>;
  deleteApiKey(apiKeyId: number): Promise<void>;
}

export class ApiKeysBusinessLogicService {
  constructor(
    private apiKeysDashboard: IApiKeysDashboard,
    private deleteApiKeyDialog: IDeleteApiKeyDialog,
    private createApiKeyDialog: ICreateApiKeyDialog,
    private showCreatedApiKeyDialog: IShowCreatedApiKeyDialog,
    private apiKeysHttpService: IApiKeysHttpService,
  ) {}

  public async createApiKey(): Promise<void> {
    try {
      const apiKeyName = await this.createApiKeyDialog.show();

      this.createApiKeyDialog.setIsLoading(true);
      const apiKey = await this.apiKeysHttpService.createApiKey(apiKeyName);
      this.createApiKeyDialog.setIsLoading(false);
      this.createApiKeyDialog.hide();

      this.updateApiKeys();

      await this.showCreatedApiKeyDialog.show(apiKey);
    } catch (e) {
      this.createApiKeyDialog.setIsLoading(false);
      this.createApiKeyDialog.hide();

      if (e instanceof ApiKeyDialogCancelledError) return;
      SentryServiceSingleton.captureException(e as Error);
    }
  }

  public async deleteApiKey(apiKeyId: number): Promise<void> {
    try {
      await this.deleteApiKeyDialog.show();

      this.deleteApiKeyDialog.setIsLoading(true);
      await this.apiKeysHttpService.deleteApiKey(apiKeyId);
      this.deleteApiKeyDialog.setIsLoading(false);
      this.deleteApiKeyDialog.hide();

      this.updateApiKeys();
    } catch (e) {
      this.deleteApiKeyDialog.setIsLoading(false);
      this.deleteApiKeyDialog.hide();

      if (e instanceof ApiKeyDialogCancelledError) return;
      SentryServiceSingleton.captureException(e as Error);
    }
  }

  public async updateApiKeys(): Promise<void> {
    this.apiKeysDashboard.setIsLoading(true);
    const apiKeys = await this.apiKeysHttpService.getAllApiKeys();
    this.apiKeysDashboard.setApiKeys(apiKeys);
    this.apiKeysDashboard.setIsLoading(false);
  }
}
