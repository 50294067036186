import { NavigationGuardNext, Route } from 'vue-router';

import store from '@/store';
import { AuthServiceSingleton } from '../services/auth/auth.service';
import { LocalStorage, StorageKeys } from '@/services/local-storage';
import { SubscriptionStatus } from '@/services/backend';

/**
 * Sets free account flag for ordering.
 * Redirects to dashboard, if user is authenticated and already has subscription.
 * Redirects to free account flow, if user is authenticated and has no subscription.
 */
export async function freeRegistrationGuard(_to: Route, _from: Route, next: NavigationGuardNext): Promise<void> {
  await store.dispatch('order/enableFreeAccountMode');

  await store.dispatch('order/saveStateToLocalStorage');

  if (!AuthServiceSingleton.isAuthenticated()) {
    AuthServiceSingleton.clearSessionData();
    next();
    return;
  }

  await store.dispatch('profile/fetchSubscriptionStatus');

  const accountHasNoSubscription =
    store.state.profile.subscriptionStatus?.status === SubscriptionStatus.NO_SUBSCRIPTION;

  if (accountHasNoSubscription) {
    next('/order-subscription/address');
  } else {
    next('/');
  }
}

/**
 * Removes free account flag and sets query params for ordering.
 * Redirects to dashboard, if user is authenticated and has paid subscription.
 */
export async function registrationGuard(to: Route, _from: Route, next: NavigationGuardNext): Promise<void> {
  await store.dispatch('order/disableFreeAccountMode');

  const hubspotDealId = to.query['hubspot-deal-id'];
  if (hubspotDealId) {
    LocalStorage.setString(StorageKeys.HUBSPOT_DEAL_ID, hubspotDealId as string);
  }

  const couponId = to.query.coupon;
  if (couponId) {
    LocalStorage.setString(StorageKeys.COUPON_ID, couponId as string);
  }

  await store.dispatch('order/saveStateToLocalStorage');

  if (!AuthServiceSingleton.isAuthenticated()) {
    AuthServiceSingleton.clearSessionData();
    next();
    return;
  }

  await store.dispatch('profile/fetchSubscriptionStatus');

  const isSubscriptionAccount: boolean = store.getters['profile/isSubscriptionAccount'];

  if (isSubscriptionAccount) {
    next('/');
  } else {
    next('/order-subscription/licences');
  }
}
