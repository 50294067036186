import { IAppState } from '@/store';
import { ILayoutState } from '@/store/modules/layout/layout.interfaces';
import { GetterTree } from 'vuex';

export const layoutGetters: GetterTree<ILayoutState, IAppState> = {
  isMobileDeviceWidth(state): boolean {
    return state.windowWidth > 0 && state.windowWidth <= 980;
  },

  isMobileDeviceWidthExcludingLarge(state): boolean {
    return state.windowWidth > 0 && state.windowWidth <= 780;
  },

  isMobileDeviceWidthOnlySmall(state): boolean {
    return state.windowWidth > 0 && state.windowWidth <= 420;
  },
};
