import { render, staticRenderFns } from "./OnboardingSteps.vue?vue&type=template&id=63fe99ba&scoped=true"
import script from "./OnboardingSteps.vue?vue&type=script&lang=ts"
export * from "./OnboardingSteps.vue?vue&type=script&lang=ts"
import style0 from "./OnboardingSteps.vue?vue&type=style&index=0&id=63fe99ba&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63fe99ba",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VSkeletonLoader})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Resize from 'vuetify/lib/directives/resize'
installDirectives(component, {Resize})
