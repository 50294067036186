





























import {
  IRoutingStepperConfigItem,
  IRoutingStepperConfigItemExtended,
} from '@/shared/routing-stepper/routing-stepper.interfaces';
import { getActiveRouteIndex, getExtendedStepperItems } from '@/shared/routing-stepper/routing-stepper.utils';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class RoutingStepper extends Vue {
  @Prop({ type: Array, default: () => [] }) protected stepperConfig!: IRoutingStepperConfigItem[];

  protected get currentStepIndex(): number {
    return getActiveRouteIndex(this.stepperConfig, this.$route.path);
  }

  @Watch('$route', { immediate: true, deep: true })
  protected onRouteChange(): void {
    this.updateStepperValues();
  }

  protected stepperValues: IRoutingStepperConfigItemExtended[] = [];

  mounted(): void {
    this.updateStepperValues();
  }

  private updateStepperValues(): void {
    if (!this.stepperConfig.length) {
      return;
    }

    this.stepperValues = getExtendedStepperItems(this.stepperConfig, this.currentStepIndex);
  }
}
