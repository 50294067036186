







































































import { Component, Vue } from 'vue-property-decorator';
import { StripePaymentElementChangeEvent } from '@stripe/stripe-js';
import * as Sentry from '@sentry/vue';

import store from '@/store';
import { SupportedPaymentMethod } from '@/store/modules/order';
import { ICommonInfoDialogButton } from '@/shared/common-info-dialog';
import { StripeServiceSingleton } from '@/services/stripe/stripe.service';
import { formatPaymentMethod } from './utils/formatters.utils';

import CommonInfoDialog from '@/shared/common-info-dialog/CommonInfoDialog.vue';
@Component({
  components: { CommonInfoDialog },
})
export default class OrderSubscriptionPayment extends Vue {
  protected isLoading = true;
  protected isErrorDialogVisible = false;
  protected isValid = false;

  private stripeService = StripeServiceSingleton;
  private paymentErrorText = '';

  protected get isPaymentIntentCreated(): boolean {
    return !!store.state.order.paymentDetails.stripePaymentMethodId;
  }

  protected get existingPaymentMethod(): string {
    return this.isPaymentIntentCreated ? formatPaymentMethod(store.state.order.paymentDetails.paymentMethod) : '';
  }

  protected get errorDialogTitle(): string {
    if (store.state.order.paymentDetails.paymentMethod === 'card') {
      return 'Zahlung mit Kreditkarte fehlgeschlagen';
    }

    return 'Sepa-Zahlung fehlgeschlagen';
  }

  protected get errorDialogText(): string {
    if (store.state.order.paymentDetails.paymentMethod === 'card') {
      return (
        'Ihre Kreditkartendaten konnten nicht bestätigt werden. ' +
        'Bitte versuchen Sie es erneut oder wählen ' +
        'Sie eine andere Bezahlmethode bzw. eine andere Kreditkarte aus.'
      );
    }

    return this.paymentErrorText;
  }

  protected get errorDialogButtons(): ICommonInfoDialogButton[] {
    return [{ color: 'secondary', text: 'OK' }];
  }

  protected mounted(): void {
    if (!this.isPaymentIntentCreated) {
      this.mountStripeElement();
    } else {
      this.isLoading = false;
      this.isValid = true;
    }
  }

  protected async completePaymentSetupAndContinue(): Promise<void> {
    if (!this.isPaymentIntentCreated) {
      await this.createPaymentMethod();
    } else {
      await this.$router.push('/order-subscription/summary');
    }
  }

  protected async resetPaymentMethod(): Promise<void> {
    this.isLoading = true;
    this.isValid = false;

    this.$store.commit('order/stripePaymentMethodId', '');
    this.$store.commit('order/paymentMethod', '');

    await this.stripeService.initPaymentElement();
    this.mountStripeElement();
  }

  protected onErrorDialogClose(): void {
    this.isErrorDialogVisible = false;
  }

  private mountStripeElement() {
    if (!this.stripeService.isReady) {
      setTimeout(this.mountStripeElement.bind(this), 10);
      return;
    }

    this.stripeService
      .mountPaymentElement('#payment-element', this.updateHandler.bind(this))
      .then(() => (this.isLoading = false));
  }

  private updateHandler(event: StripePaymentElementChangeEvent) {
    this.updateValidation(event);
    this.storePaymentDetails(event);
  }

  private updateValidation(event: StripePaymentElementChangeEvent) {
    this.isValid = event.complete;
  }

  private storePaymentDetails(event: StripePaymentElementChangeEvent) {
    if (event.complete) {
      this.$store.commit('order/paymentMethod', event.value.type as SupportedPaymentMethod);
    }
  }

  private async createPaymentMethod(): Promise<void> {
    this.isLoading = true;

    try {
      const stripePaymentMethodId = await this.stripeService.confirmPaymentMethod();
      this.$store.commit('order/stripePaymentMethodId', stripePaymentMethodId);

      await this.$router.push('/order-subscription/summary');
    } catch (error) {
      Sentry.captureException(error);

      this.isErrorDialogVisible = true;

      if (error instanceof Error) {
        this.paymentErrorText = error.message;
      } else {
        this.paymentErrorText = 'Something went wrong';
      }
    }

    this.isLoading = false;
  }
}
