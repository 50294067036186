import { ActionContext } from 'vuex';

import { AuthServiceSingleton } from '@/services/auth/auth.service';
import { IAuthUser } from '@/services/auth/auth.interfaces';
import { LocalStorage, StorageKeys } from '@/services/local-storage';
import { IdanaSubscriptionPlan } from '@/shared/interfaces';
import { IAppState } from '@/store';
import { IOrderState, IStripeCouponInfo } from '@/store/modules';
import { orderStateDefault } from '@/store/modules/order/order.state';

export const isCouponProductPlanCorrect = (plan: IdanaSubscriptionPlan | null): boolean => {
  if (!plan) return false;

  if (plan === IdanaSubscriptionPlan.IDANA_FLEXIBLE) return true;
  if (plan === IdanaSubscriptionPlan.IDANA_PLUS) return true;

  return false;
};

export const filterSecureDataFromStorage = (state: IOrderState): Partial<IOrderState> => {
  return {
    ...state,
    paymentDetails: orderStateDefault.paymentDetails,
    idanaSubscription: {
      ...state.idanaSubscription,
      stripeCouponInfo: null,
    },
  };
};

/**
 * Why we need this mapping function:
 *   While developing I have faced an issue, that when I change/rename store properties
 *   it still will restore old values from the localStorage.
 *   And as result it breaks app, because we do not validate the restored state.
 *   This function will prevent such issues.
 *   It maps through state object and if the value is present - restores it, otherwise it will ignore it and use default values.
 *   This is crucial for production, because changes are possible, and nothing stops user from getting into similar situation,
 *   when trying to continue purchase after some time will break the flow.
 */
function mapObjectProps(
  initialObj: Record<string, unknown>,
  sourceObj: Record<string, unknown>,
): Record<string, unknown> {
  const restoredObj = Object.assign({}, initialObj);

  for (const key in restoredObj) {
    if (!Object.hasOwn(sourceObj, key) || sourceObj[key] === null || sourceObj[key] === undefined) {
      continue;
    }

    if (typeof restoredObj[key] === 'object' && !Array.isArray(restoredObj[key]) && restoredObj[key] !== null) {
      // if object, recursively map object
      restoredObj[key] = mapObjectProps(
        restoredObj[key] as Record<string, unknown>,
        sourceObj[key] as Record<string, unknown>,
      );
    } else if (typeof restoredObj[key] === 'object' && Array.isArray(restoredObj[key])) {
      // if array, copy every item of array if defined
      restoredObj[key] = (restoredObj[key] as Array<unknown>).map((item, index) => {
        return (sourceObj[key] as Array<unknown>)[index] || item;
      });
    } else {
      // else, just copy value
      restoredObj[key] = sourceObj[key];
    }
  }

  return restoredObj;
}

export function restoreStateWithDefaults(stateDefaults: IOrderState, loadedState: IOrderState): IOrderState {
  /** This is needed for working with the order state as an object. */
  type IOrderStateWithIndex = IOrderState & Record<string, unknown>;

  return mapObjectProps(
    stateDefaults as IOrderStateWithIndex,
    loadedState as IOrderStateWithIndex,
  ) as IOrderStateWithIndex;
}

export const getPriceValueWithDiscount = (price: number, discountPercent: number): number => {
  return price * (1 - discountPercent / 100);
};

export const getDiscountMonthsCount = (couponInfo: IStripeCouponInfo | null): number => {
  if (!couponInfo) return 0;
  if (!couponInfo.duration) return 0;
  if (!couponInfo.idanaSubscriptionPlan) return 0;

  const isYearlySubscription = couponInfo.idanaSubscriptionPlan === IdanaSubscriptionPlan.IDANA_PLUS;

  if (couponInfo.duration === 'forever') return 100;
  if (couponInfo.duration === 'once' && isYearlySubscription) return 12;
  if (couponInfo.duration === 'once' && !isYearlySubscription) return 1;
  // Not sure about this case
  if (couponInfo.duration === 'repeating' && isYearlySubscription) return couponInfo.durationInMonths || 12;
  if (couponInfo.duration === 'repeating' && !isYearlySubscription) return couponInfo.durationInMonths || 0;

  return 0;
};

export const getPlanPricePerYearWithDiscount = (
  percentOff: number | null,
  pricePerMonth: number,
  discountMonthsCount: number,
): number => {
  const discountPercent = percentOff || 0;
  let discountMonthsCountWithinYear = discountMonthsCount ? discountMonthsCount : 0;

  if (discountMonthsCountWithinYear > 12) {
    discountMonthsCountWithinYear = 12;
  }

  const monthCountWithoutDiscount = 12 - discountMonthsCountWithinYear;
  const priceWithDiscountPerMonth = getPriceValueWithDiscount(pricePerMonth, discountPercent);

  return pricePerMonth * monthCountWithoutDiscount + priceWithDiscountPerMonth * discountMonthsCountWithinYear;
};

export const restoreMissingStateValues = (
  restoredState: IOrderState,
  context: ActionContext<IOrderState, IAppState>,
): void => {
  if (!restoredState.institutionName) {
    const institutionNameFromBackend = context.rootState.profile.customer?.institutionName;
    const institutionNameFromRegistration = LocalStorage.getString(StorageKeys.INSTITUTION_NAME);
    const fallbackInstitutionName = institutionNameFromBackend || institutionNameFromRegistration || '';
    context.commit('institutionName', fallbackInstitutionName);
  }

  if (!restoredState.email && AuthServiceSingleton.isAuthenticated()) {
    const authUser: IAuthUser | undefined = AuthServiceSingleton.getUser();
    const emailFromAuth = authUser ? authUser.email : '';
    context.commit('email', emailFromAuth);
  }

  // couponId from local storage is the source of truth (set via url parameter)
  // only overwrites if it is set (https://tomesone.atlassian.net/browse/IDA-3348)
  const couponId = LocalStorage.getString(StorageKeys.COUPON_ID);
  if (couponId) {
    context.commit('stripeCouponId', couponId);
  }

  if (!restoredState.isFreeAccount && LocalStorage.getString(StorageKeys.FREE_ACCOUNT_ACTIVE) === 'true') {
    context.commit('isFreeAccount', true);
  }
};
