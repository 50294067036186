

































































import { Component, Vue } from 'vue-property-decorator';

import store from '@/store';
import { IdanaOnboardingPlan } from '@/shared/interfaces';
import {
  idanaSubscriptionPlanBasicBenefits,
  idanaSubscriptionPlanBasicPlusBenefits,
} from './idana-product-card/idana-product-card.const';
import { IIdanaProductBenefitsItem } from './idana-product-card/idana-product-card.interfaces';

import IdanaProductCard from './idana-product-card/IdanaProductCard.vue';

@Component({
  components: { IdanaProductCard },
})
export default class OrderSubscriptionOnboarding extends Vue {
  IdanaOnboardingPlan = IdanaOnboardingPlan;

  get isMediumMobileDevice(): boolean {
    return store.getters['layoutModule/isMobileDeviceWidthExcludingLarge'];
  }

  get selectedPlan(): IdanaOnboardingPlan | null {
    return store.state.order.onboarding.selectedPlan;
  }

  set selectedPlan(plan: IdanaOnboardingPlan | null) {
    store.commit('order/selectedOnboardingPlan', plan);
  }

  get onboardingPrices(): Record<IdanaOnboardingPlan, number> {
    return store.state.order.onboarding.prices;
  }

  get benefitsBasicPlusList(): IIdanaProductBenefitsItem[] {
    return idanaSubscriptionPlanBasicPlusBenefits;
  }

  get benefitsBasicList(): IIdanaProductBenefitsItem[] {
    return idanaSubscriptionPlanBasicBenefits;
  }

  protected beforeDestroy(): void {
    store.dispatch('order/saveStateToLocalStorage');
  }

  get onboardingPlanName(): string {
    switch (this.selectedPlan) {
      case IdanaOnboardingPlan.IDANA_ONBOARDING_BASIS:
        return 'Einrichtung Basis';
      case IdanaOnboardingPlan.IDANA_ONBOARDING_BASIS_PLUS:
        return 'Einrichtung Basis Plus';
      default:
        return '';
    }
  }
}
