import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import { multiGuard } from './multiguard.util';
import {
  isAuthenticatedGuard,
  isAuthenticatedGuardAllowDeeplinks,
  redirectToDashboardIfAuthenticated,
} from './auth.guards';
import { freeRegistrationGuard, registrationGuard } from './registration.guards';
import { redirectBasedOnSubscriptionStatus } from './customer.guards';
import { plausibleTracking } from './plausible';
import {
  orderDataPrefillingOnEnter,
  subscriptionPlanGuard,
  subscriptionAddressGuard,
  subscriptionPaymentGuard,
  subscriptionLicencesGuard,
  freePlanSummaryGuard,
} from './order-subscriptions.guards';

import OrderSubscriptionAddress from '@/modules/order-subscription/OrderSubscriptionAddress.vue';
import OrderSubscriptionLicences from '@/modules/order-subscription/OrderSubscriptionLicences.vue';
import OrderSubscriptionEditorPlan from '@/modules/order-subscription/OrderSubscriptionEditorPlan.vue';
import OrderSubscriptionPayment from '@/modules/order-subscription/OrderSubscriptionPayment.vue';
import OrderSubscriptionPlanRedesign from '@/modules/order-subscription/OrderSubscriptionPlanRedesign.vue';
import OrderSubscriptionFreePlanSummary from '@/modules/order-subscription/OrderSubscriptionFreePlanSummary.vue';
import OrderSubscriptionRootPage from '@/modules/order-subscription/OrderSubscriptionRootPage.vue';
import OrderSubscriptionSummary from '@/modules/order-subscription/OrderSubscriptionSummary.vue';
import OrderSubscriptionSuccess from '@/modules/order-subscription/OrderSubscriptionSuccess.vue';
import OrderSubscriptionOnboarding from '@/modules/order-subscription/OrderSubscriptionOnboarding.vue';

import DashboardPage from '@/routes/DashboardPage.vue';

import SignaturePage from '@/routes/SignaturePage.vue';

import ApiKeysPage from '@/routes/ApiKeysPage.vue';

import SignUpCredentials from '@/modules/sign-up/SignUpCredentials.vue';
import SignUpInstitutionInfo from '@/modules/sign-up/SignUpInstitutionInfo.vue';
import SignUpMedicalSpecialties from '@/modules/sign-up/SignUpMedicalSpecialties.vue';
import SignUpRootPage from '@/modules/sign-up/SignUpRootPage.vue';

import RegistrationPage from '@/routes/RegistrationPage.vue';
import FreeRegistrationPage from '@/routes/FreeRegistrationPage.vue';
import LoginPage from '@/routes/LoginPage.vue';
import AuthCallback from '@/routes/AuthCallback.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Dashboard',
    component: DashboardPage,
    beforeEnter: multiGuard([isAuthenticatedGuard, redirectBasedOnSubscriptionStatus]),
  },
  {
    path: '/account/signatures',
    name: 'SignaturePage',
    component: SignaturePage,
    beforeEnter: multiGuard([isAuthenticatedGuardAllowDeeplinks, redirectBasedOnSubscriptionStatus]),
  },
  {
    path: '/settings/api',
    name: 'ApiKeysPage',
    component: ApiKeysPage,
    beforeEnter: multiGuard([isAuthenticatedGuardAllowDeeplinks, redirectBasedOnSubscriptionStatus]),
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    beforeEnter: redirectToDashboardIfAuthenticated,
  },
  {
    path: '/start',
    name: 'Registration',
    component: RegistrationPage,
    beforeEnter: registrationGuard,
  },
  {
    path: '/free',
    name: 'FreeRegistration',
    component: FreeRegistrationPage,
    beforeEnter: freeRegistrationGuard,
  },
  {
    path: '/auth-callback',
    name: 'AuthCallback',
    component: AuthCallback,
    beforeEnter: redirectToDashboardIfAuthenticated,
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: SignUpRootPage,
    beforeEnter: redirectToDashboardIfAuthenticated,
    children: [
      {
        path: 'institution-info',
        component: SignUpInstitutionInfo,
      },
      {
        path: 'medical-specialties',
        component: SignUpMedicalSpecialties,
      },
      {
        path: 'credentials',
        component: SignUpCredentials,
      },
    ],
  },
  {
    path: '/order-subscription',
    name: 'OrderSubscription',
    component: OrderSubscriptionRootPage,
    beforeEnter: orderDataPrefillingOnEnter,
    children: [
      {
        path: 'licences',
        component: OrderSubscriptionLicences,
        beforeEnter: isAuthenticatedGuard,
      },
      {
        path: 'address',
        component: OrderSubscriptionAddress,
        beforeEnter: multiGuard([isAuthenticatedGuard, subscriptionLicencesGuard]),
      },
      {
        path: 'plan',
        component: OrderSubscriptionPlanRedesign,
        beforeEnter: multiGuard([isAuthenticatedGuard, subscriptionLicencesGuard, subscriptionAddressGuard]),
      },
      {
        path: 'onboarding',
        component: OrderSubscriptionOnboarding,
        beforeEnter: multiGuard([
          isAuthenticatedGuard,
          subscriptionLicencesGuard,
          subscriptionAddressGuard,
          subscriptionPlanGuard,
        ]),
      },
      {
        path: 'editor',
        component: OrderSubscriptionEditorPlan,
        beforeEnter: multiGuard([
          isAuthenticatedGuard,
          subscriptionLicencesGuard,
          subscriptionAddressGuard,
          subscriptionPlanGuard,
        ]),
      },
      {
        path: 'payment',
        component: OrderSubscriptionPayment,
        beforeEnter: multiGuard([
          isAuthenticatedGuard,
          subscriptionLicencesGuard,
          subscriptionAddressGuard,
          subscriptionPlanGuard,
        ]),
      },
      {
        path: 'summary',
        component: OrderSubscriptionSummary,
        beforeEnter: multiGuard([
          isAuthenticatedGuard,
          subscriptionLicencesGuard,
          subscriptionAddressGuard,
          subscriptionPlanGuard,
          subscriptionPaymentGuard,
        ]),
      },
      {
        path: 'free-plan-summary',
        component: OrderSubscriptionFreePlanSummary,
        beforeEnter: multiGuard([isAuthenticatedGuard, freePlanSummaryGuard]),
      },
      {
        path: 'success',
        component: OrderSubscriptionSuccess,
        beforeEnter: multiGuard([
          isAuthenticatedGuard,
          subscriptionLicencesGuard,
          subscriptionAddressGuard,
          subscriptionPlanGuard,
          subscriptionPaymentGuard,
        ]),
      },
    ],
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(plausibleTracking);

export default router;
