import { IIdanaProductBenefitsItem } from './idana-product-card.interfaces';

export const idanaSubscriptionPlanBasicBenefits: IIdanaProductBenefitsItem[] = [
  { icon: 'mdi-check', colorClass: '', title: 'Gespräch zur Planung der Einführung' },
  { icon: 'mdi-check', colorClass: '', title: 'Einrichtung der GDT-Schnittstelle' },
  { icon: 'mdi-check', colorClass: '', title: '1 Fragebogen-Digitalisierung inklusive' },
  { icon: 'mdi-check', colorClass: '', title: 'Umfangreiche Schulungsmaterialien' },
];

export const idanaSubscriptionPlanBasicPlusBenefits: IIdanaProductBenefitsItem[] = [
  { icon: 'mdi-check', colorClass: '', title: 'Gespräch zur Planung der Einführung' },
  { icon: 'mdi-check', colorClass: '', title: 'Einrichtung der GDT-Schnittstelle' },
  { icon: 'mdi-check', colorClass: '', title: '1 Fragebogen-Digitalisierung inklusive' },
  { icon: 'mdi-check', colorClass: '', title: 'Umfangreiche Schulungsmaterialien' },
  { icon: 'mdi-check', colorClass: 'primary--text', title: 'Remote Schulung für das gesamte Team' },
];

export const idanaSubscriptionPlanMonthlyBenefits: IIdanaProductBenefitsItem[] = [
  { icon: 'mdi-check', colorClass: 'primary--text', title: 'Monatlich kündbar' },
];

export const idanaSubscriptionPlanYearlyBenefits: IIdanaProductBenefitsItem[] = [
  { icon: 'mdi-check', colorClass: 'primary--text', title: 'Jährliche Abrechnung' },
  { icon: 'mdi-check', colorClass: 'primary--text', title: '1 Fragebogen Digitalisierung inklusive' },
];

export const idanaEditorBenefitsList: IIdanaProductBenefitsItem[] = [
  { icon: 'mdi-check', colorClass: '', title: 'Beliebig viele digitale Fragebögen & Formulare erstellen' },
  { icon: 'mdi-check', colorClass: '', title: 'Bestehende Idana-Fragebögen selbst anpassen' },
  {
    icon: 'mdi-check',
    colorClass: '',
    title: 'Beinhaltet alle Funktionen, darunter Bedingungen, Scores und Fließtexterstellung',
  },
  { icon: 'mdi-check', colorClass: '', title: 'Schulungsvideos und Formelsammlung inklusive' },
];
