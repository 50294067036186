















































import { Vue, Component, Emit, Ref, Watch, Prop } from 'vue-property-decorator';

type FormRule = (value: string) => true | string;
type VForm = Vue & { reset: () => void };

@Component({})
export default class SignatureSinglePinForm extends Vue {
  @Prop({ type: Boolean, required: false, default: false }) value!: boolean;

  @Ref('form') form!: VForm;

  protected showPin = false;
  protected isValid = false;
  protected formData = {
    pin: '',
  };

  protected get hasContent(): boolean {
    return !!this.$slots.title || !!this.$slots.description;
  }

  protected get formRules(): Record<string, FormRule[]> {
    return {
      pin: [(pin) => !!pin || 'Das ist ein Pflichtfeld!'],
    };
  }

  @Watch('isValid')
  @Emit('input')
  protected validChanged(): boolean {
    return this.isValid;
  }

  public getPinIfValid(): string | null {
    if (!this.isValid) return null;
    return this.formData.pin;
  }

  public reset(): void {
    this.form.reset();
  }
}
