







































































































import { Component, Prop, Vue } from 'vue-property-decorator';

import { formatMoneyAmount } from '../utils/format-money-amount.filter';

@Component({
  filters: { formatMoneyAmount },
})
export default class SummarySubscriptionCostsSection extends Vue {
  @Prop({ required: true, type: Boolean, default: false }) protected isMonthlyPlan!: boolean;
  @Prop({ required: true, type: Boolean, default: false }) protected isPlanWithDiscount!: boolean;
  @Prop({ required: true, type: Number, default: 0 }) protected totalPlanCostWithTaxesAndDiscount!: number;
  @Prop({ required: true, type: Number, default: 0 }) protected totalPlanCostWithTaxes!: number;
  @Prop({ required: true, type: Number, default: 0 }) protected planCost!: number;
  @Prop({ required: true, type: Number, default: 0 }) protected planCostWithDiscount!: number;
  @Prop({ required: true, type: Number, default: 0 }) protected discountPercent!: number;
  @Prop({ required: true, type: Number, default: 0 }) protected discountMonthsCount!: number;

  protected get taxesCostWithDiscount(): number {
    return this.planCostWithDiscount * 0.19;
  }

  protected get taxesCost(): number {
    return this.planCost * 0.19;
  }
}
