import { WebAuth } from 'auth0-js';

import store from '@/store';
import CONFIG from '../../config';
import { IAuthUser, ISessionData } from './auth.interfaces';
import { LocalStorage, StorageKeys } from '../local-storage';

const loginAuth = new WebAuth({
  ...CONFIG.auth0Config,
  redirectUri: `${CONFIG.auth0Config.redirectUriBase}/auth-callback`,
});

const registerAuth = new WebAuth({
  ...CONFIG.auth0Config,
  redirectUri: `${CONFIG.auth0Config.redirectUriBase}/sign-up/credentials`,
});

class AuthService {
  private idToken: string | undefined;
  private accessToken: string | undefined;
  private expiresAt: number | undefined;
  private user: IAuthUser | undefined;
  private scopes: string[] | undefined;
  private webAuth: auth0.WebAuth = loginAuth;

  private static deleteStoredData() {
    LocalStorage.removeItem(StorageKeys.SESSION);

    void store.dispatch('order/removeOrderStateFromStorage');
  }

  constructor() {
    this.deserialize();
  }

  public getToken(): string | undefined {
    return this.idToken;
  }

  private setToken(idToken: string | undefined) {
    this.idToken = idToken;
  }

  public getAccessToken(): string | undefined {
    return this.accessToken;
  }

  private setAccessToken(accessToken: string | undefined) {
    this.accessToken = accessToken;
  }

  public getExpiresAt(): number | undefined {
    return this.expiresAt;
  }

  private calculateExpiresAt(expiresIn: number | undefined) {
    if (!expiresIn) return;
    const expiresAt = expiresIn * 1000 + new Date().getTime();
    this.setExpiresAt(expiresAt);
  }

  private setExpiresAt(expiresAt: number | undefined) {
    this.expiresAt = expiresAt;
  }

  public getUser(): IAuthUser {
    if (!this.user) throw new Error('User is not set. Log in first.');
    return this.user;
  }

  private setUser(user: IAuthUser | undefined) {
    this.user = user;
  }

  public getScopes(): string[] | undefined {
    return this.scopes;
  }

  private setScopes(scopes: string[] | undefined) {
    this.scopes = scopes;
  }

  public isAuthenticated(): boolean {
    const loggedInTill = this.getExpiresAt();
    if (!loggedInTill) return false;
    return new Date().getTime() < loggedInTill;
  }

  public hasScopes(requiredScopes: string[]): boolean {
    const scopes = this.getScopes();
    if (!scopes) return false;
    return requiredScopes.every((scope) => scopes.includes(scope));
  }

  public login(): void {
    this.webAuth = loginAuth;
    this.webAuth.authorize({ mode: 'login' });
  }

  public register(): void {
    this.webAuth = registerAuth;
    this.webAuth.authorize({ mode: 'signUp', screen_hint: 'signup' });
  }

  public handleAuthentication(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.webAuth.parseHash((error, authResult) => {
        if (error) {
          console.log(error);
          this.logoutAndRedirectToLogin();
          if (error.errorDescription) return reject(new Error(`${error.error}: ${error.errorDescription}`));
          else if (error.error_description) return reject(new Error(`${error.error}: ${error.error_description}`));
          else return reject(new Error(error.error));
        }

        if (authResult && authResult.accessToken && authResult.idToken) {
          this.setSession(authResult);
          return resolve();
        }

        console.warn('No access token or id token found');

        this.logoutAndRedirectToLogin();
        return reject(new Error('No access token or id token found'));
      });
    });
  }

  private setSession(authResult: auth0.Auth0DecodedHash) {
    this.calculateExpiresAt(authResult.expiresIn);
    this.setAccessToken(authResult.accessToken);
    this.setToken(authResult.idToken);
    this.setUser(authResult.idTokenPayload as IAuthUser);
    this.setScopes(authResult.scope ? authResult.scope.split(' ') : undefined);
    this.scheduleTokenRenewal();
    this.serialize();
  }

  private scheduleTokenRenewal() {
    if (this.expiresAt) {
      const delay = this.expiresAt - Date.now();

      if (delay > 0) {
        setTimeout(this.renewToken.bind(this), delay);
      }
    }
  }

  private renewToken() {
    this.webAuth.checkSession({}, (error, result) => {
      if (error) {
        console.error(error);
        return;
      }

      this.setSession(result);
    });
  }

  /** Removes session data and redirects to login page */
  public logoutAndRedirectToLogin(): void {
    AuthService.deleteStoredData();

    this.webAuth.logout({
      returnTo: `${window.location.origin}/login`, // Allowed logout URL listed in dashboard
      clientID: CONFIG.auth0Config.clientID,
    });
  }

  /** Removes session data */
  public clearSessionData(): void {
    AuthService.deleteStoredData();
  }

  private serialize() {
    const sessionData: ISessionData = {
      idToken: this.idToken,
      accessToken: this.accessToken,
      expiresAt: this.expiresAt,
      user: this.user,
      scopes: this.scopes,
    };

    try {
      LocalStorage.setObject<ISessionData>(StorageKeys.SESSION, sessionData);
    } catch (error) {
      console.error(error);
      // Failed serializing the data, but we don't do anything
    }
  }

  private deserialize() {
    const sessionData: ISessionData | null = LocalStorage.getObject<ISessionData>(StorageKeys.SESSION);

    if (!sessionData) return;

    try {
      this.setExpiresAt(sessionData.expiresAt);
      this.setAccessToken(sessionData.accessToken);
      this.setToken(sessionData.idToken);
      this.setUser(sessionData.user);
      this.setScopes(sessionData.scopes);
      this.scheduleTokenRenewal();
    } catch (error) {
      console.error(error);
      // Stored data is invalid. Delete it
      AuthService.deleteStoredData();
    }
  }
}

export const AuthServiceSingleton = new AuthService();
