import { formatNumber } from './formatters.utils';

export function formatMoneyAmount(value: number, hideZeroesIfInteger = false) {
  if (!value && value !== 0) return '';
  if (typeof value !== 'number') return '';

  let minDigitsValue = 2;

  if (hideZeroesIfInteger && value % 1 === 0) {
    minDigitsValue = 0;
  }

  return formatNumber(value, minDigitsValue);
}
