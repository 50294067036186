import Vue from 'vue';
import * as Sentry from '@sentry/vue';

import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import { StripeServiceSingleton } from './services/stripe';
import config from '@/config';

import App from './App.vue';

Sentry.init({
  app: Vue,
  dsn: 'https://c68669b818eb4982b121ae35d20a1224@o157572.ingest.sentry.io/6541184',
  environment: config.environment,
  release: process.env.APP_VERSION,
});

// Stripe is not necessary at this point as its only needed for ordering.
// We should move the stripe initialization to a point
// where we definitely know that we need it.
StripeServiceSingleton.init().catch((error) => {
  Sentry.captureException(error);
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
