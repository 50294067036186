export enum StorageKeys {
  INSTITUTION_NAME = 'INSTITUTION_NAME',
  MEDICAL_SPECIALTIES = 'MEDICAL_SPECIALTIES',
  COUPON_ID = 'COUPON_ID',
  HUBSPOT_DEAL_ID = 'HUBSPOT_DEAL_ID',
  FREE_ACCOUNT_ACTIVE = 'FREE_ACCOUNT_ACTIVE',

  /** Auth Session */
  SESSION = 'SESSION',

  /**
   * Route the user requested and should be redirected to after login.
   * Enables deep links even if user is not logged in.
   */
  USER_REQUESTED_DEEPLINK_ROUTE = 'USER_REQUESTED_DEEPLINK_ROUTE',

  /** Vuex State Module: Order */
  STATE_ORDER = 'STATE_ORDER',
}
