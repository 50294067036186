



















import { Component, Vue } from 'vue-property-decorator';

import store from '@/store';

@Component({})
export default class OrderSubscriptionSuccess extends Vue {
  mounted(): void {
    store.dispatch('order/removeOrderStateFromStorage');
    store.dispatch('order/removeOrderSpecificInfoFromStorageAfterOrder');
  }

  protected goToDashboard(): void {
    this.$router.push({ path: '/' });
  }
}
