
















import { Vue, Component } from 'vue-property-decorator';

import store from '@/store';
import avatarCaterina from '@/assets/avatar-caterina-linder.png';
import { IIdanaSupportBarConfig } from '@/shared/idana-support-bar';

import AppHeader from '@/components/AppHeader.vue';
import AppFooter from '@/components/AppFooter.vue';
import IdanaSupportBar from '@/shared/idana-support-bar/IdanaSupportBar.vue';
import Onboarding from '@/modules/onboarding/Onboarding.vue';
import FreeAccount from '@/modules/free-account/FreeAccount.vue';

const supportBarOnboarding: IIdanaSupportBarConfig = {
  imageSrc: avatarCaterina,
  employee: 'Caterina Linder',
  phone: '0761 6006784 38',
};

const supportBarFreeAccount: IIdanaSupportBarConfig = {
  phone: '0761 60067841',
};

@Component({
  components: {
    AppHeader,
    IdanaSupportBar,
    Onboarding,
    FreeAccount,
    AppFooter,
  },
})
export default class DashboardPage extends Vue {
  protected get isSubscriptionAccount(): boolean {
    return store.getters['profile/isSubscriptionAccount'];
  }

  protected get isFreeAccount(): boolean {
    return store.getters['profile/isFreeAccount'];
  }

  protected get supportBarConfig(): IIdanaSupportBarConfig | null {
    if (this.isSubscriptionAccount) return supportBarOnboarding;
    if (this.isFreeAccount) return supportBarFreeAccount;
    return null;
  }

  beforeMount() {
    store.dispatch('profile/fetchCustomer');
  }
}
