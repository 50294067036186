



























































import { Component, Prop, Vue } from 'vue-property-decorator';

import { IIdanaSupportBarConfig } from '@/shared/idana-support-bar/idana-support-bar.interfaces';
import IdanaSupportBar from '@/shared/idana-support-bar/IdanaSupportBar.vue';
import idanaLogoSubtitle from '@/assets/idana-logo.svg';
import idanaIcon from '@/assets/idana-icon.svg';

@Component({
  components: { IdanaSupportBar },
})
export default class SplitPageLayout extends Vue {
  @Prop({ type: String, default: '', required: true }) protected sideNavTitle!: string;
  @Prop({ type: String, default: '' }) protected sideNavText!: string;
  @Prop({ type: Boolean, default: false }) protected isSupportBarEnabled!: string;
  @Prop({ type: Object, default: undefined }) protected supportBarConfig: IIdanaSupportBarConfig | undefined;

  protected isSupportBarVisible = false;
  protected idanaLogoSubtitle = idanaLogoSubtitle;
  protected idanaIcon = idanaIcon;

  protected showSupportBar(): void {
    this.isSupportBarVisible = true;
  }

  protected hideSupportBar(): void {
    this.isSupportBarVisible = false;
  }
}
