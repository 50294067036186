import { NavigationGuardNext, Route } from 'vue-router';

import store from '@/store';

export function orderDataPrefillingOnEnter(_to: Route, _from: Route, next: NavigationGuardNext): void {
  store.dispatch('profile/fetchCustomer').finally(() => {
    store.dispatch('order/restoreFromLocalStorage').finally(() => next());
  });
}

export function freePlanSummaryGuard(_to: Route, _from: Route, next: NavigationGuardNext): void {
  if (store.state.order.isFreeAccount) {
    next();
  } else {
    next('/order-subscription/summary');
  }
}

export function subscriptionLicencesGuard(_to: Route, _from: Route, next: NavigationGuardNext): void {
  if (store.state.order.idanaSubscription.numberOfLicences > 0 || store.state.order.isFreeAccount) {
    next();
  } else {
    next('/order-subscription/licences');
  }
}

export function subscriptionAddressGuard(_to: Route, _from: Route, next: NavigationGuardNext): void {
  if (store.getters['order/isSubscriptionAddressValid']) {
    next();
  } else {
    next('/order-subscription/address');
  }
}

export function subscriptionPlanGuard(_to: Route, _from: Route, next: NavigationGuardNext): void {
  if (store.getters['order/isSubscriptionPlanSelected'] || store.state.order.isFreeAccount) {
    next();
  } else {
    next('/order-subscription/plan');
  }
}

export function subscriptionPaymentGuard(_to: Route, _from: Route, next: NavigationGuardNext): void {
  if (store.getters['order/isSubscriptionPaymentSet'] || store.state.order.isFreeAccount) {
    next();
  } else {
    next('/order-subscription/payment');
  }
}
