import { ActionTree } from 'vuex';

import { IAppState } from '@/store/index';
import { IProfileState } from './profile.interfaces';
import { BackendService } from '@/services/backend';

export const profileActions: ActionTree<IProfileState, IAppState> = {
  async fetchCustomer(store) {
    try {
      const customer = await BackendService.getCustomer();
      store.commit('setCustomer', customer);
    } catch (error) {
      console.error(error);
    }
  },

  async fetchSubscriptionStatus(store) {
    try {
      const subscriptionStatus = await BackendService.getSubscriptionStatus();
      store.commit('setSubscriptionStatus', subscriptionStatus);
    } catch (error) {
      console.error(error);
    }
  },
};
