import { render, staticRenderFns } from "./SignatureDrawArea.vue?vue&type=template&id=562094f6&scoped=true"
import script from "./SignatureDrawArea.vue?vue&type=script&lang=ts"
export * from "./SignatureDrawArea.vue?vue&type=script&lang=ts"
import style0 from "./SignatureDrawArea.vue?vue&type=style&index=0&id=562094f6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "562094f6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VIcon})


/* vuetify-loader */
import installDirectives from "!../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Resize from 'vuetify/lib/directives/resize'
installDirectives(component, {Resize})
