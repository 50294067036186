





















































import { Vue, Component, Ref } from 'vue-property-decorator';

import avatarCaterina from '@/assets/avatar-caterina-linder.png';
import AppHeader from '@/components/AppHeader.vue';
import AppFooter from '@/components/AppFooter.vue';
import IdanaSupportBar from '@/shared/idana-support-bar/IdanaSupportBar.vue';

import store from '@/store';
import { IIdanaSupportBarConfig } from '@/shared/idana-support-bar';
import ApiKeysDashboard from '@/modules/settings/api/ApiKeysDashboard.vue';
import CreateApiKeyDialog from '@/modules/settings/api/CreateApiKeyDialog.vue';
import DeleteApiKeyDialog from '@/modules/settings/api/DeleteApiKeyDialog.vue';
import ShowCreatedApiKeyDialog from '@/modules/settings/api/ShowCreatedApiKeyDialog.vue';
import { ApiKeysBusinessLogicService } from '@/modules/settings/api/api-keys.business-logic-service';
import {
  IApiKeyResponseData,
  IApiKeysDashboard,
  ICreateApiKeyDialog,
  IDeleteApiKeyDialog,
  IShowCreatedApiKeyDialog,
} from '@/modules/settings/api/api-keys.interfaces';
import { ApiKeysHttpServiceSingleton } from '@/modules/settings/api/api-keys.http-service';
import { CopyToClipboardService } from '@/services/copyToClipboard/copy-to-clipboard.service';
import CommonInfoDialog from '@/shared/common-info-dialog/CommonInfoDialog.vue';

@Component({
  provide: { copyToClipboardService: new CopyToClipboardService() },
  components: {
    AppHeader,
    AppFooter,
    IdanaSupportBar,
    ApiKeysDashboard,
    ShowCreatedApiKeyDialog,
    CreateApiKeyDialog,
    DeleteApiKeyDialog,
    CommonInfoDialog,
  },
})
export default class ApiKeysPage extends Vue {
  @Ref('apiKeysDashboard') protected apiKeysDashboard!: IApiKeysDashboard;
  @Ref('createApiKeyDialog') protected createApiKeyDialog!: ICreateApiKeyDialog;
  @Ref('showCreatedApiKeyDialog') protected showCreatedApiKeyDialog!: IShowCreatedApiKeyDialog;
  @Ref('deleteApiKeyDialog') deleteApiKeyDialog!: IDeleteApiKeyDialog;

  private businessLogicService?: ApiKeysBusinessLogicService;

  protected beforeMount() {
    store.dispatch('profile/fetchCustomer');
  }

  protected mounted() {
    this.businessLogicService = new ApiKeysBusinessLogicService(
      this.apiKeysDashboard,
      this.deleteApiKeyDialog,
      this.createApiKeyDialog,
      this.showCreatedApiKeyDialog,
      ApiKeysHttpServiceSingleton,
    );

    this.businessLogicService.updateApiKeys();
  }

  protected isPatientDataTransferDisclaimerVisible = false;

  protected patientDataTransferDisclaimerText = `
Hinweis zum Datenschutz: Bitte stellen Sie als für den Datenschutz Verantwortlicher sicher, dass Ihre Patienten zum Zeitpunkt der Übermittlung von Patientenstammdaten an Idana über die API auf Ihre Datenschutzerklärung zugreifen können und diese dort darüber informiert werden, wie Sie die personenbezogenen Daten Ihrer Patienten verarbeiten. Unter anderem sollte dort erwähnt werden, dass die Patientendaten von ihren Auftragsverarbeitern verarbeitet werden, die IT-Services für die Praxisverwaltung erbringen (Kategorie von Empfängern).\n
Mit dem von Idana erzeugten und von Ihnen genutzten API-Key werden Patientenstammdaten (Vorname, Nachname, Geburtsdatum, Geschlecht, je nach Bedarf auch E-Mail-Adresse und Praxis-interne Patienten-ID) inklusive einer mit einem Idana Check-In Code assoziierten Fragebogen-Konstellation an die Idana AG übermittelt, sodass die Idana-API-Software einen personalisierten Idana-Befragungslink bereitstellen kann.\n
Es erfolgt dabei keine Übermittlung von personenbezogenen Daten in aus Datenschutzsicht unsichere Drittländer. Die Patientenstammdaten werden so lange bei der Idana AG gespeichert bis sie von der Praxis gelöscht werden. Für den Fall, dass der Patient seinen Befragungslink nicht nutzt, um die Befragung zu beantworten, werden die Patientenstammdaten automatisch 30 Tage nach Erzeugung des Befragungslinks gelöscht. Es werden durch die Nutzung der Idana-API keine automatisierten Entscheidungen in Bezug auf den Patienten getroffen.
    `;

  protected patientDataTransferDisclaimerHeadline = 'Hinweise zur Übermittlung von Patientendaten';

  protected patientDataTransferDisclaimerButtons = [{ color: 'white', text: 'Schließen' }];

  protected get isMobile(): boolean {
    return store.getters['layoutModule/isMobileDeviceWidthExcludingLarge'];
  }

  protected get supportBarConfig(): IIdanaSupportBarConfig | null {
    return {
      imageSrc: avatarCaterina,
      employee: 'Caterina Linder',
      phone: '0761 6006784 38',
    };
  }

  protected async createApiKey() {
    if (!this.businessLogicService) return;
    this.businessLogicService.createApiKey();
  }

  protected async deleteApiKey(apiKey: IApiKeyResponseData) {
    if (!this.businessLogicService) return;
    this.businessLogicService.deleteApiKey(apiKey.id);
  }
}
