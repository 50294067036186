





































































































import { Component, Ref, Vue, Watch } from 'vue-property-decorator';

import store from '@/store/index';
import * as Sentry from '@sentry/vue';

import { SignatureInvalidPinError, SignatureServiceSingleton } from './signature.service';
import { ISignatureResponseData } from './signature.interfaces';

import SignerForm from './SignerForm.vue';
import SignatureNewPinForm from './SignatureNewPinForm.vue';
import SignatureSinglePinForm from './SignatureSinglePinForm.vue';
import SignatureDrawable from './SignatureDrawable.vue';

@Component({
  components: {
    SignerForm,
    SignatureNewPinForm,
    SignatureSinglePinForm,
    SignatureDrawable,
  },
})
export default class EditSignatureDialog extends Vue {
  protected isAlertVisible = false;
  protected alertText = 'Etwas ist schiefgelaufen.';

  protected isVisible = false;
  protected isLoading = false;
  protected updatePin = false;
  protected updateSignatureImage = false;

  protected isSignatureValid = false;
  protected isSignerFormValid = false;
  protected isConfirmPinFormValid = false;
  protected isNewPinFormValid = false;

  private resolveAction: ((isCreated: boolean) => void) | null = null;
  private signature: ISignatureResponseData | null = null;

  @Ref('signerForm') protected signerForm!: SignerForm;
  @Ref('confirmPinForm') protected confirmPinForm!: SignatureSinglePinForm;
  @Ref('newPinForm') protected newPinForm!: SignatureNewPinForm | undefined;
  @Ref('signatureDrawable') protected signatureDrawable!: SignatureDrawable | undefined;

  protected get isValid(): boolean {
    const hasNewPinAndIsValid = !this.updatePin || this.isNewPinFormValid;
    const isImageValid = !this.updateSignatureImage || this.isSignatureValid;

    return (
      this.isSignerFormValid && this.isConfirmPinFormValid && isImageValid && hasNewPinAndIsValid && !!this.signature
    );
  }

  protected get isMobile(): boolean {
    return store.getters['layoutModule/isMobileDeviceWidthExcludingLarge'];
  }

  public async show(signature: ISignatureResponseData): Promise<boolean> {
    this.isVisible = true;
    this.signature = signature;

    return new Promise((resolve) => {
      this.resolveAction = resolve;
    });
  }

  protected async onClickUpdateSignature() {
    this.isLoading = true;
    await this.updateSignature().finally(() => {
      this.isLoading = false;
    });
  }

  protected closeDialog(): void {
    if (this.resolveAction) this.resolveAction(false);
    this.isVisible = false;
  }

  @Watch('isVisible')
  protected onIsVisibleChanged(isVisible: boolean) {
    if (!isVisible) this.resetDialog();
  }

  private async updateSignature() {
    const publicKey = store.state.profile.customer?.publicKey;

    if (!publicKey) {
      this.showAlert('Etwas is schiefgelaufen.');
      console.error('publicKey is undefined');
      return;
    }

    if (!this.resolveAction) {
      this.showAlert('Etwas is schiefgelaufen.');
      console.error('resolveAction is undefined');
      return;
    }

    const signer = this.signerForm.getSignerIfValid();
    const imagePngUri = this.signatureDrawable?.getImageIfValid() || undefined;
    const pin = this.confirmPinForm.getPinIfValid();
    const newPin = this.newPinForm?.getPinIfValid() || undefined;

    if (!this.isValid || !signer || !pin || !this.signature) {
      this.showAlert('Bitte überprüfen Sie alle Eingaben.');
      console.error('Some data is invalid or undefined.');
      return;
    }

    try {
      const { id, pinArgonHashConfig } = this.signature;

      await SignatureServiceSingleton.updateSignature(
        id,
        { signer, newPin, imagePngUri },
        { pin, pinArgonHashConfig, publicKey },
      );

      this.resolveAction(true);
      this.isVisible = false;
    } catch (error) {
      if (error instanceof SignatureInvalidPinError) {
        this.showAlert('PIN falsch');
      } else {
        Sentry.captureException(error);
        this.showAlert('Etwas is schiefgelaufen.');
      }
      this.confirmPinForm.reset();
    }
  }

  private showAlert(text: string, timeout = 4000): void {
    this.alertText = text;
    this.isAlertVisible = true;

    setTimeout(() => {
      this.isAlertVisible = false;
      this.alertText = 'Etwas ist schiefgelaufen.';
    }, timeout);
  }

  private resetDialog(): void {
    this.signature = null;
    this.updatePin = false;
    this.updateSignatureImage = false;
    this.isAlertVisible = false;
    this.isLoading = false;

    this.resolveAction = null;

    this.signerForm.reset();
    this.confirmPinForm.reset();
    this.newPinForm?.reset();
    this.signatureDrawable?.clearSignature();
  }
}
