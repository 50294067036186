import { MutationTree } from 'vuex';

import { ICustomer, ISubscriptionStatus } from '@/services/backend';
import { IProfileState } from '@/store/modules';

export const profileMutations: MutationTree<IProfileState> = {
  setCustomer(state, newCustomer: ICustomer): void {
    state.customer = Object.assign({}, newCustomer);
  },

  setSubscriptionStatus(state, newStatus: ISubscriptionStatus): void {
    state.subscriptionStatus = Object.assign({}, newStatus);
  },
};
