







































































import { IdanaSignature } from '@tomesgmbh/idana-signature';
import { Component, Emit, Prop, Ref, Vue, Watch } from 'vue-property-decorator';

import SignatureDrawArea from './SignatureDrawArea.vue';

@Component({
  components: {
    SignatureDrawArea,
  },
})
export default class SignatureDrawable extends Vue {
  @Prop({ type: Boolean, required: false, default: false }) value!: boolean;

  @Ref('signatureDrawArea') signatureDrawArea!: SignatureDrawArea;
  protected idanaSignature: IdanaSignature | null = null;

  protected showDialog = false;
  protected showInFullscreen = false;

  private signatureImage: string | null = null;

  mounted(): void {
    this.updateShowInFullscreen();
  }

  private get isValid(): boolean {
    return this.signatureImage !== null;
  }

  protected updateShowInFullscreen(): void {
    // NOTE: this handling is necessary because Vuetify css works based on viewport width,
    // mobile in landscape is often > 800px
    const width = window.innerWidth;
    const height = window.innerHeight;
    const narrowSide = Math.min(width, height);
    const isLandscape = width > height;

    this.showInFullscreen = narrowSide < 600 && isLandscape;
  }

  protected openSignatureDrawDialog(): void {
    this.showDialog = true;
    this.signatureDrawArea?.init();
  }

  protected saveSignature(): void {
    this.signatureImage = this.signatureDrawArea.getImageIfValid();
    this.closeDialog();
  }

  protected closeDialog(): void {
    this.showDialog = false;
  }

  public clearSignature(): void {
    this.signatureImage = null;
  }

  @Watch('isValid')
  @Emit('input')
  protected onIsValidChange(): boolean {
    return this.isValid;
  }

  public getImageIfValid(): string | null {
    if (this.isValid) return this.signatureImage;
    else return null;
  }
}
