



























































import { Vue, Component } from 'vue-property-decorator';

import store from '@/store';
import { freeAccountItems } from './free-account.constants';

import FreeAccountImage from './assets/free-account.jpg';
import FreeAccountContentItem from './FreeAccountContentItem.vue';

@Component({
  components: {
    FreeAccountContentItem,
  },
})
export default class FreeAccount extends Vue {
  protected readonly IDANA_HELP_CENTER_URL = 'https://hilfe.idana.com';
  protected readonly freeAccountImage = FreeAccountImage;
  protected readonly freeAccountItems = freeAccountItems;

  protected get daysLeft(): number | null {
    if (!store.state.profile.subscriptionStatus) return null;
    return store.state.profile.subscriptionStatus.daysLeft;
  }

  beforeMount() {
    // we need to clear the free account mode here
    // because otherwise its possible that the user clicks on "Idana kaufen"
    // but ends up in the free account flow (again!)
    store.dispatch('order/disableFreeAccountMode');
  }
}
