












































import { Vue, Component, Watch } from 'vue-property-decorator';

import * as Sentry from '@sentry/vue';

import { SignatureServiceSingleton } from './signature.service';

@Component({})
export default class DeleteSignatureDialog extends Vue {
  protected isVisible = false;
  protected isLoading = false;
  protected isAlertVisible = false;
  protected alertText = 'Etwas ist schiefgelaufen.';

  private resolveAction: ((isDeleted: boolean) => void) | null = null;
  private signatureId: number | null = null;

  public async showDeleteDialog(signatureId: number): Promise<boolean> {
    this.isVisible = true;
    this.signatureId = signatureId;

    return new Promise((resolve) => {
      this.resolveAction = resolve;
    });
  }

  protected async onClickDeleteSignature() {
    this.isLoading = true;
    await this.deleteSignature().finally(() => {
      this.isLoading = false;
    });
  }

  protected closeDialog(): void {
    if (this.resolveAction) this.resolveAction(false);
    this.isVisible = false;
  }

  @Watch('isVisible')
  protected onIsVisibleChanged(isVisible: boolean) {
    if (!isVisible) this.resetDialog();
  }

  private async deleteSignature(): Promise<void> {
    if (!this.signatureId) {
      this.showAlert('Etwas is schiefgelaufen.');
      console.error('signatureId is undefined');
      return;
    }

    if (!this.resolveAction) {
      this.showAlert('Etwas is schiefgelaufen.');
      console.error('resolveAction is undefined');
      return;
    }

    try {
      await SignatureServiceSingleton.deleteSignature(this.signatureId);

      this.resolveAction(true);
      this.isVisible = false;
    } catch (error) {
      Sentry.captureException(error);
      this.showAlert('Etwas is schiefgelaufen.');
      console.error(error);
    }
  }

  private showAlert(text: string, timeout = 4000): void {
    this.alertText = text;
    this.isAlertVisible = true;

    setTimeout(() => {
      this.isAlertVisible = false;
      this.alertText = 'Etwas ist schiefgelaufen.';
    }, timeout);
  }

  private resetDialog(): void {
    this.isVisible = false;
    this.signatureId = null;
    this.isLoading = false;

    this.resolveAction = null;
  }
}
