import { IdanaSubscriptionPlan } from '@/shared/interfaces';
import { IOrderState } from '@/store/modules';

import { formatNumber } from '../utils/formatters.utils';
import {
  idanaSubscriptionPlanMonthlyBenefits,
  idanaSubscriptionPlanYearlyBenefits,
} from './idana-subscription-info.const';

export function getIdanaSubscriptionPlanName(plan: IdanaSubscriptionPlan | null): string {
  if (plan === IdanaSubscriptionPlan.IDANA_FLEXIBLE) return 'Flexible';
  if (plan === IdanaSubscriptionPlan.IDANA_PLUS) return 'Plus';

  return 'Fehler';
}

export function getIdanaSubscriptionPlanPeriodHint(plan: IdanaSubscriptionPlan | null): string {
  if (plan === IdanaSubscriptionPlan.IDANA_FLEXIBLE) return 'pro Monat';
  if (plan === IdanaSubscriptionPlan.IDANA_PLUS) return 'jährlich';

  return '';
}

export function getSubscriptionPlanTotalCost(orderState: IOrderState, idanaProduct: IdanaSubscriptionPlan): number {
  const productPrice = orderState.idanaSubscription.subscriptionPrices[idanaProduct];
  const licencesCount = orderState.idanaSubscription.numberOfLicences;

  return idanaProduct === IdanaSubscriptionPlan.IDANA_FLEXIBLE
    ? productPrice * licencesCount
    : productPrice * licencesCount * 12;
}

export function getIdanaSubscriptionPlanPrice(orderState: IOrderState, plan: IdanaSubscriptionPlan | null): number {
  if (plan === null) return 0;

  return orderState.idanaSubscription.subscriptionPrices[plan] || 0;
}

export const getDiscountText = (plan: IdanaSubscriptionPlan, discountValue: number, discountDuration = 0): string => {
  const isMonthlyPlan = plan === IdanaSubscriptionPlan.IDANA_FLEXIBLE;
  if (isMonthlyPlan) {
    if (discountDuration === 1) {
      return `${formatNumber(discountValue)}% Rabatt für ${discountDuration} Monat`;
    } else if (discountDuration > 1) {
      return `${formatNumber(discountValue)}% Rabatt für ${discountDuration} Monate`;
    }
    return `${formatNumber(discountValue)}% Rabatt`;
  } else {
    return `${formatNumber(discountValue)}% Rabatt für 1 Jahr`;
  }
};

export function getIdanaSubscriptionPlanBenefits(plan: IdanaSubscriptionPlan | null): string[] {
  if (plan === IdanaSubscriptionPlan.IDANA_FLEXIBLE) {
    return idanaSubscriptionPlanMonthlyBenefits;
  }

  if (plan === IdanaSubscriptionPlan.IDANA_PLUS) {
    return idanaSubscriptionPlanYearlyBenefits;
  }

  return [];
}
