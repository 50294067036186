










import { Vue, Component } from 'vue-property-decorator';

import { WindowSizeListenerServiceSingleton } from '@/services/window-size-listener';

import OutdatedSupportedBrowserMessage from '@/components/OutdatedSupportedBrowserMessage.vue';
import HttpRequestFailureDialog from '@/shared/http-requests-handler/HttpRequestFailureDialog.vue';

import '@fontsource/roboto/latin.css';
import '@mdi/font/css/materialdesignicons.css';

@Component({
  components: {
    OutdatedSupportedBrowserMessage,
    HttpRequestFailureDialog,
  },
})
export default class App extends Vue {
  protected mounted(): void {
    WindowSizeListenerServiceSingleton.initResizeEventListener();
  }
}
