import { Module } from 'vuex';

import { IAppState } from '@/store/index';
import { IProfileState } from './profile.interfaces';
import { profileStateDefault } from './profile.state';
import { profileGetters } from './profile.getters';
import { profileMutations } from './profile.mutations';
import { profileActions } from './profile.actions';

export const profileModule: Module<IProfileState, IAppState> = {
  namespaced: true,
  state: JSON.parse(JSON.stringify(profileStateDefault)) as IProfileState,
  getters: profileGetters,
  mutations: profileMutations,
  actions: profileActions,
};
