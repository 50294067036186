import { StorageKeys } from './local-storage.keys';

export class LocalStorage {
  private static isAvailable(): boolean {
    return window.localStorage && 'localStorage' in window;
  }

  public static removeItem(key: StorageKeys): void {
    if (LocalStorage.isAvailable()) {
      localStorage.removeItem(key);
    }
  }

  public static getString(key: StorageKeys): string | null {
    if (LocalStorage.isAvailable()) {
      return localStorage.getItem(key);
    }
    return null;
  }

  public static getNumber(key: StorageKeys): number | null {
    if (LocalStorage.isAvailable()) {
      const cachedData = localStorage.getItem(key);
      return cachedData ? Number(cachedData) : null;
    }
    return null;
  }

  public static getObject<T>(key: StorageKeys): T | null {
    if (LocalStorage.isAvailable()) {
      const cachedData = localStorage.getItem(key);
      return cachedData ? (JSON.parse(cachedData) as T) : null;
    }
    return null;
  }

  public static setString(key: StorageKeys, value: string): void {
    if (LocalStorage.isAvailable()) {
      localStorage.setItem(key, value);
    }
  }

  public static setNumber(key: StorageKeys, value: number): void {
    if (LocalStorage.isAvailable()) {
      localStorage.setItem(key, value.toString());
    }
  }

  public static setObject<T>(key: StorageKeys, value: T): void {
    if (LocalStorage.isAvailable()) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }
}
