





















































































import { Vue, Component, Emit, Ref } from 'vue-property-decorator';

import { ISignatureResponseData } from './signature.interfaces';
import { SignatureServiceSingleton } from './signature.service';

import SignaturePreview from './SignaturePreview.vue';
import SignatureDashboardQrCodeOverlay, {
  ISignatureDashboardQrCodeOverlay,
} from './SignatureDashboardQrCodeOverlay.vue';

@Component({
  components: {
    SignaturePreview,
    SignatureDashboardQrCodeOverlay,
  },
})
export default class SignatureDashboard extends Vue {
  protected signatures: ISignatureResponseData[] = [];
  protected isLoading = true;

  @Ref('signatureDashboardQrCodeOverlay') signatureDashboardQrCodeOverlay!: ISignatureDashboardQrCodeOverlay;

  async created(): Promise<void> {
    await this.updateSignaturesList();
  }

  protected get hasSignatures(): boolean {
    return this.signatures.length > 0;
  }

  @Emit('create-signature')
  protected createSignature(): void {
    return;
  }

  @Emit('edit-signature')
  protected editSignature(signature: ISignatureResponseData): ISignatureResponseData {
    return signature;
  }

  @Emit('delete-signature')
  protected onDeleteSignature(signatureId: number): number {
    return signatureId;
  }

  protected showQrCodeOverlay() {
    this.signatureDashboardQrCodeOverlay.showOverlay();
  }

  public async updateSignaturesList(): Promise<void> {
    this.isLoading = true;
    this.signatures = await SignatureServiceSingleton.getAllSignatures();
    this.isLoading = false;
  }
}
