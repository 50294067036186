import { Module } from 'vuex';

import { orderStateDefault } from './order.state';
import { orderGetters } from './order.getters';
import { orderMutations } from './order.mutations';
import { orderActions } from './order.actions';
import { IOrderState } from './order.interfaces';
import { IAppState } from '@/store';

export const orderModule: Module<IOrderState, IAppState> = {
  namespaced: true,
  state: JSON.parse(JSON.stringify(orderStateDefault)) as IOrderState,
  getters: orderGetters,
  mutations: orderMutations,
  actions: orderActions,
};
