import {
  IHttpFailureDialogConfig,
  IHttpFailureDialogState,
} from '@/store/modules/http-failure-dialog/http-failure-dialog.interfaces';

export const httpFailureDialogConfigDefault: IHttpFailureDialogConfig = {
  isVisible: false,
  buttons: [],
  text: '',
  title: '',
};

export const httpFailureDialogStateDefault: IHttpFailureDialogState = {
  config: Object.assign({}, httpFailureDialogConfigDefault),
};
