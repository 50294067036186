





































































import { Vue, Component, Emit } from 'vue-property-decorator';
import { IApiKeyResponseData, IApiKeysDashboard } from './api-keys.interfaces';
import config from '@/config';
import { Environment } from '@/config/config.interfaces';

import ApiKeysDashboardTable from './ApiKeysDashboardTable.vue';

@Component({
  components: { ApiKeysDashboardTable },
})
export default class ApiKeysDashboard extends Vue implements IApiKeysDashboard {
  protected isLoading = false;
  protected apiKeys: IApiKeyResponseData[] = [];

  protected get apiDocURL(): string {
    if (config.environment == Environment.DEVELOPMENT) {
      return 'https://api.idana.dev/external/v1/docs/';
    }
    return 'https://api.idana.app/external/v1/docs/';
  }

  protected get hasApiKeys(): boolean {
    return this.apiKeys.length > 0;
  }

  @Emit('create-api-key')
  protected createApiKey(): void {
    return;
  }

  @Emit('delete-api-key')
  protected deleteApiKey(item: IApiKeyResponseData): IApiKeyResponseData {
    return item;
  }

  public setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  public setApiKeys(apiKeys: IApiKeyResponseData[]): void {
    this.apiKeys = apiKeys;
  }
}
