





















































































import { Component, Vue } from 'vue-property-decorator';

import store from '@/store';
import { isZipCode } from './utils/validators.utils';
import { isValidEmail, isEmailLocalPartAsciiOnly } from '@tomesgmbh/idana-types';

@Component
export default class OrderSubscriptionAddress extends Vue {
  private emailError = '';
  private zipCodeError = '';

  protected get isMediumMobileDevice(): boolean {
    return store.getters['layoutModule/isMobileDeviceWidthExcludingLarge'];
  }

  protected get isFreeAccountMode(): boolean {
    return store.state.order.isFreeAccount;
  }

  protected get institutionName(): string {
    return store.state.order.institutionName;
  }

  protected set institutionName(newInstitutionName: string) {
    store.commit('order/institutionName', newInstitutionName);
  }

  protected get email(): string {
    const email = store.state.order.email;
    if (email && email.length > 0) {
      // trigger email validation if pre-set in store
      this.emailError = this.validateEmail(email);
    }
    return store.state.order.email;
  }

  protected set email(newEmail: string) {
    this.emailError = this.validateEmail(newEmail);
    if (!this.emailError) {
      store.commit('order/email', newEmail);
    }
  }

  protected get streetAndHouseNumber(): string {
    return store.state.order.address.streetAndHouseNumber;
  }

  protected set streetAndHouseNumber(newStreetAndHouseNumber: string) {
    store.commit('order/streetAndHouseNumber', newStreetAndHouseNumber);
  }

  protected get city(): string {
    return store.state.order.address.city;
  }

  protected set city(newCity: string) {
    store.commit('order/city', newCity);
  }

  protected get zipCode(): string {
    return store.state.order.address.zipCode;
  }

  protected set zipCode(newZipCode: string) {
    this.zipCodeError = this.validateZip(newZipCode);
    if (!this.zipCodeError) {
      store.commit('order/zipCode', newZipCode);
    }
  }

  get isValid(): boolean {
    return (
      !!this.institutionName &&
      !!this.streetAndHouseNumber &&
      !!this.city &&
      this.zipCodeError === '' &&
      this.emailError === ''
    );
  }

  protected beforeDestroy(): void {
    if (this.isValid) {
      store.dispatch('order/saveStateToLocalStorage');
    }
  }

  protected switchToNextPage(): void {
    if (this.isFreeAccountMode) {
      this.$router.push({ path: '/order-subscription/free-plan-summary' });
    } else {
      this.$router.push({ path: '/order-subscription/plan' });
    }
  }

  private validateZip(zipCode: string): string {
    return isZipCode(zipCode) ? '' : 'PLZ nicht OK';
  }

  private validateEmail(email: string): string {
    if (!isEmailLocalPartAsciiOnly(email)) return 'Email-Adressen mit Umlauten werden nicht unterstützt.';

    if (!isValidEmail(email)) return 'Email ist nicht gültig';

    return '';
  }
}
