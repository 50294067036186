







































import { Component, Prop, Vue } from 'vue-property-decorator';

import { formatMoneyAmount } from '../utils/format-money-amount.filter';
import IdanaSubscriptionInfoBenefitsListItem from './IdanaSubscriptionInfoBenefitsListItem.vue';

@Component({
  filters: { formatMoneyAmount },
  components: {
    IdanaSubscriptionInfoBenefitsListItem,
  },
})
export default class IdanaSubscriptionInfo extends Vue {
  @Prop({ required: true, type: Array, default: () => [] }) protected benefitsList!: string[];
  @Prop({ required: true, type: Number, default: 0 }) protected price!: number;
  @Prop({ required: false, type: Number, default: 0 }) protected priceWithDiscount!: number;
  @Prop({ required: false, type: String, default: '' }) protected productHint!: string;
  @Prop({ required: false, type: Boolean, default: false }) protected isSelected!: boolean;
  @Prop({ required: false, type: Boolean, default: false }) protected isHighlighted!: boolean;
  @Prop({ required: false, type: Boolean, default: false }) protected isDiscountUsed!: boolean;
  @Prop({ required: false, type: String, default: '' }) protected discountText!: string;
  @Prop({ required: true, type: Number, default: 0 }) protected licencesCount!: number;
  @Prop({ required: true, type: Number, default: 0 }) protected totalCost!: number;
  @Prop({ required: false, type: Number, default: 0 }) protected totalCostWithDiscount!: number;

  protected getPrice() {
    return this.priceWithDiscount <= 0 ? this.price : this.priceWithDiscount;
  }
}
