





































































import { Component, Ref, Vue, Watch } from 'vue-property-decorator';

import store from '@/store/index';
import * as Sentry from '@sentry/vue';

import { SignatureServiceSingleton } from './signature.service';

import SignerForm from './SignerForm.vue';
import SignatureNewPinForm from './SignatureNewPinForm.vue';
import SignatureDrawable from './SignatureDrawable.vue';

@Component({
  components: {
    SignerForm,
    SignatureNewPinForm,
    SignatureDrawable,
  },
})
export default class CreateSignatureDialog extends Vue {
  protected isVisible = false;
  protected isLoading = false;
  protected isAlertVisible = false;
  protected alertText = 'Etwas ist schiefgelaufen.';

  private resolveAction: ((isCreated: boolean) => void) | null = null;
  protected isSignerFormValid = false;
  protected isSignatureValid = false;
  protected isPinFormValid = false;

  @Ref('signerForm') protected signerForm!: SignerForm;
  @Ref('signatureNewPinForm') protected signatureNewPinForm!: SignatureNewPinForm;
  @Ref('signatureDrawable') protected signatureDrawable!: SignatureDrawable;

  protected get isValid(): boolean {
    return this.isSignerFormValid && this.isPinFormValid && this.isSignatureValid;
  }

  protected get isMobile(): boolean {
    return store.getters['layoutModule/isMobileDeviceWidthExcludingLarge'];
  }

  public async show(): Promise<boolean> {
    this.isVisible = true;

    return new Promise((resolve) => {
      this.resolveAction = resolve;
    });
  }

  protected async onClickCreateSignature() {
    this.isLoading = true;
    await this.createSignature().finally(() => {
      this.isLoading = false;
    });
  }

  protected closeDialog(): void {
    if (this.resolveAction) this.resolveAction(false);
    this.isVisible = false;
  }

  @Watch('isVisible')
  protected onIsVisibleChanged(isVisible: boolean) {
    if (!isVisible) this.resetDialog();
  }

  private async createSignature() {
    const publicKey = store.state.profile.customer?.publicKey;

    if (!publicKey) {
      this.showAlert('Etwas is schiefgelaufen.');
      console.error('publicKey is undefined');
      return;
    }

    if (!this.resolveAction) {
      this.showAlert('Etwas is schiefgelaufen.');
      console.error('resolveAction is undefined');
      return;
    }

    const signer = this.signerForm.getSignerIfValid();
    const imagePngUri = this.signatureDrawable.getImageIfValid();
    const pin = this.signatureNewPinForm.getPinIfValid();

    if (!signer || !pin || !imagePngUri) {
      this.showAlert('Etwas is schiefgelaufen.');
      console.error('signer, pin or image is undefined');
      return;
    }

    try {
      await SignatureServiceSingleton.createSignature({ signer, imagePngUri, pin }, publicKey);

      this.resolveAction(true);
      this.isVisible = false;
    } catch (error) {
      Sentry.captureException(error);
      this.showAlert('Unterschrift konnte nicht erstellt werden.');
    }
  }

  private showAlert(text: string, timeout = 4000): void {
    this.alertText = text;
    this.isAlertVisible = true;

    setTimeout(() => {
      this.isAlertVisible = false;
      this.alertText = 'Etwas ist schiefgelaufen.';
    }, timeout);
  }

  private resetDialog(): void {
    this.isAlertVisible = false;
    this.isLoading = false;

    this.resolveAction = null;

    this.signerForm.reset();
    this.signatureNewPinForm.reset();
    this.signatureDrawable.clearSignature();
  }
}
