









































import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { IShowCreatedApiKeyDialog, ICopyToClipboardService } from './api-keys.interfaces';

import ShowCreatedApiKeyInput from './ShowCreatedApiKeyInput.vue';

@Component({
  components: {
    ShowCreatedApiKeyInput,
  },
})
export default class ShowCreatedApiKeyDialog extends Vue implements IShowCreatedApiKeyDialog {
  @Prop({ type: Boolean, default: false }) fullscreen!: boolean;

  private resolveAction: (() => void) | null = null;
  private rejectAction: ((error: Error) => void) | null = null;

  protected isVisible = false;
  protected playCopySuccessAnimation = false;
  protected playCopyErrorAnimation = false;
  protected apiKey = '';

  @Inject('copyToClipboardService') copyToClipboardService!: ICopyToClipboardService;

  public show(apiKey: string): Promise<void> {
    this.apiKey = apiKey;
    this.isVisible = true;

    return new Promise((resolve, reject) => {
      this.resolveAction = resolve;
      this.rejectAction = reject;
    });
  }

  protected closeDialog(): void {
    this.isVisible = false;
    if (this.resolveAction) this.resolveAction();
  }

  protected async copyToClipboard() {
    if (!this.rejectAction || !this.resolveAction) return;

    try {
      await this.copyToClipboardService.copyToClipboard(this.apiKey);
      this.setPlayCopySuccessAnimation(true);
      setTimeout(() => this.setPlayCopySuccessAnimation(false), 1000);
    } catch (e) {
      this.rejectAction(e as Error);
      this.setPlayCopyErrorAnimation(true);
      setTimeout(() => this.setPlayCopyErrorAnimation(false), 1000);
    }
  }

  private setPlayCopySuccessAnimation(playCopySuccessAnimation: boolean) {
    this.playCopySuccessAnimation = playCopySuccessAnimation;
  }

  private setPlayCopyErrorAnimation(playCopyErrorAnimation: boolean) {
    this.playCopyErrorAnimation = playCopyErrorAnimation;
  }
}
