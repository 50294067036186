




































import { Vue, Component, Ref, Prop, Watch, Emit } from 'vue-property-decorator';

import { IApiKeyCreateRequest } from './api-keys.interfaces';

type FormRule = (value: string) => true | string;
type VForm = Vue & { reset: () => void };

@Component({})
export default class ApiKeyDescriptionForm extends Vue {
  @Prop({ type: Boolean, required: false, default: false }) value!: boolean;

  @Ref('form') form!: VForm;

  protected isValid = false;
  protected formData: IApiKeyCreateRequest = {
    name: '',
  };

  protected get formRules(): Record<string, FormRule[]> {
    return {
      name: [(name) => !!name || 'Bezeichnung ist ein Pflichtfeld!'],
    };
  }

  @Watch('isValid')
  @Emit('input')
  protected validChanged(): boolean {
    return this.isValid;
  }

  public getDescriptionIfValid(): IApiKeyCreateRequest | null {
    if (!this.isValid) return null;
    return this.formData;
  }

  public reset(): void {
    this.form.reset();
  }
}
