




















import * as Sentry from '@sentry/vue';
import { Vue, Component } from 'vue-property-decorator';

import store from '@/store';
import { HttpRequestsHandler } from '@/shared/http-requests-handler';
import { BackendService, CustomerRegistrationHTTPStatusCode } from '@/services/backend';
import { LocalStorage, StorageKeys } from '@/services/local-storage';
import { AuthServiceSingleton } from '@/services/auth/auth.service';
import { SentryServiceSingleton } from '@/services/sentry/sentry.service';
import { IDropdownItem } from './medical-specialties.interfaces';

@Component
export default class SignUpCredentials extends Vue {
  protected get isMobileDevice(): boolean {
    return store.getters['layoutModule/isMobileDeviceWidth'];
  }

  protected get isFreeAccountMode(): boolean {
    return store.state.order.isFreeAccount;
  }

  async beforeMount(): Promise<void> {
    try {
      await this.authenticateAndRegisterAccount();
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
      AuthServiceSingleton.logoutAndRedirectToLogin();
    }
  }

  private async authenticateAndRegisterAccount(): Promise<void> {
    await AuthServiceSingleton.handleAuthentication();

    const auth0Email = AuthServiceSingleton.getUser().email;

    const registrationResult = await HttpRequestsHandler.handleRequestWithRetry(
      BackendService.postRegistration,
      auth0Email,
    );

    if (registrationResult === CustomerRegistrationHTTPStatusCode.CREATED) {
      await Promise.allSettled([this.postRegistrationInfo(), this.putFavoritesByMedicalSpecialties()]);
    }

    this.$router.push({ path: '/' }).catch(() => {
      // this will throw an error because '/' will redirect again to the order flow
      // see https://stackoverflow.com/questions/62223195/vue-router-uncaught-in-promise-error-redirected-from-login-to-via-a
    });
  }

  private async putFavoritesByMedicalSpecialties(): Promise<void> {
    const medicalSpecialtiesJson = LocalStorage.getString(StorageKeys.MEDICAL_SPECIALTIES);

    if (medicalSpecialtiesJson === null) {
      SentryServiceSingleton.captureException(new Error('Medical specialties are not set.'));
      return;
    }

    try {
      const medicalSpecialties = JSON.parse(medicalSpecialtiesJson);
      await HttpRequestsHandler.handleRequestWithRetry(
        BackendService.putFavoritesByMedicalSpecialty,
        medicalSpecialties.map((specialty: IDropdownItem) => specialty.value),
      );
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  private async postRegistrationInfo(): Promise<void> {
    const institutionName = LocalStorage.getString(StorageKeys.INSTITUTION_NAME);
    const hubspotDealId = LocalStorage.getString(StorageKeys.HUBSPOT_DEAL_ID) || undefined;
    const medicalSpecialtiesJson = LocalStorage.getString(StorageKeys.MEDICAL_SPECIALTIES);

    if (medicalSpecialtiesJson === null) {
      SentryServiceSingleton.captureException(new Error('Medical specialties are not set.'));
      return;
    }

    if (!institutionName) {
      SentryServiceSingleton.captureException(new Error('Institution name is not set.'));
      return;
    }

    try {
      const medicalSpecialties = JSON.parse(medicalSpecialtiesJson);
      await HttpRequestsHandler.handleRequestWithRetry(BackendService.postRegistrationInfo, {
        institutionName,
        medicalSpecialties: medicalSpecialties.map((specialty: IDropdownItem) => specialty.value),
        hubspotDealId,
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  }
}
