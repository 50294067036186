































import * as Sentry from '@sentry/vue';
import { Component, Vue } from 'vue-property-decorator';

import { LocalStorage, StorageKeys } from '@/services/local-storage';
import { AuthServiceSingleton } from '@/services/auth/auth.service';

import { ICommonInfoDialogButton } from '@/shared/common-info-dialog';
import CommonInfoDialog from '@/shared/common-info-dialog/CommonInfoDialog.vue';

interface IAuthError {
  isShown: boolean;
  dialogTitle: string;
  dialogText: string;
  dialogButtons: ICommonInfoDialogButton[];
}

@Component({
  components: {
    CommonInfoDialog,
  },
})
export default class AuthCallback extends Vue {
  protected isLoading = false;
  protected infoText = '';
  protected error: IAuthError = {
    isShown: false,
    dialogTitle: '',
    dialogText: '',
    dialogButtons: [],
  };

  async beforeMount() {
    this.isLoading = true;

    try {
      await this.authenticateAndRedirect();
    } catch (error) {
      this.mapCustomerErrors(error);
    }

    this.isLoading = false;
  }

  private async authenticateAndRedirect() {
    await AuthServiceSingleton.handleAuthentication();

    const email = AuthServiceSingleton.getUser().email;
    this.infoText = `Konto ${email} wird geladen`;

    const requestedRoute = LocalStorage.getString(StorageKeys.USER_REQUESTED_DEEPLINK_ROUTE);
    LocalStorage.removeItem(StorageKeys.USER_REQUESTED_DEEPLINK_ROUTE);

    await this.$router.push({ path: requestedRoute || '/' });
  }

  private mapCustomerErrors(error: unknown): void {
    Sentry.captureException(error);

    if (!navigator.onLine) {
      this.error.dialogTitle = 'Sie sind offline';
      this.error.dialogText = 'Bitte überprüfen Sie Ihre Verbindung und versuchen Sie es erneut!';
      this.error.dialogButtons = [
        {
          text: 'OK',
          color: 'primary',
          action: () => {
            AuthServiceSingleton.logoutAndRedirectToLogin();
          },
        },
      ];
    } else {
      this.error.dialogTitle = 'Es ist ein Fehler aufgetreten';
      this.error.dialogText =
        'Bitte versuchen Sie es noch einmal oder kontaktieren Sie unseren Support für weitere Informationen!';
      this.error.dialogButtons = [
        {
          text: 'OK',
          color: 'primary',
          action: () => {
            AuthServiceSingleton.logoutAndRedirectToLogin();
          },
        },
      ];
    }

    this.error.isShown = true;
  }
}
