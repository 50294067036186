import config from '@/config';
import { deleteRequest, getRequest, patchRequest, postRequest } from '@/services/backend/backend.utils';
import { IArgonHashConfig, IPinArgonHash } from './crypto/crypto.interfaces';
import { CryptoService } from './crypto/crypto.service';
import {
  ICreateSignatureRawData,
  ISignatureCreateRequest,
  ISignatureResponseData,
  ISignatureUpdateRequest,
  ISignatureDeleteRequestBody,
  IUpdateSignatureRawData,
} from './signature.interfaces';

export interface ISignatureCryptoService {
  encryptWithPublicKey(data: string, publicKey: string): Promise<string>;
  createArgonPinHash(input: string, argonHashConfig?: IArgonHashConfig): Promise<IPinArgonHash>;
}

interface ISignatureHttpUtils {
  getRequest<ResponseData>(url: string): Promise<ResponseData>;
  deleteRequest<RequestData>(url: string, data?: RequestData): Promise<number>;
  patchRequest<RequestData>(url: string, data?: RequestData): Promise<number>;
  postRequest<ResponseData, RequestData>(
    url: string,
    data?: RequestData,
  ): Promise<{ status: number; data: ResponseData }>;
}

export class SignatureInvalidPinError extends Error {}

export class SignatureService {
  constructor(
    private cryptoService: ISignatureCryptoService,
    private httpUtils: ISignatureHttpUtils,
  ) {}

  public async getAllSignatures(): Promise<ISignatureResponseData[]> {
    const url = `${config.backendV2BaseUrl}/api/v1/customer/signatures`;
    const signatures = await this.httpUtils.getRequest<ISignatureResponseData[]>(url);
    return signatures;
  }

  public async createSignature(signatureData: ICreateSignatureRawData, publicKey: string): Promise<void> {
    const { signer, pin, imagePngUri } = signatureData;

    const pinArgonHashNew = await this.cryptoService.createArgonPinHash(pin);
    const imageUrlEncryptedBase64 = await this.cryptoService.encryptWithPublicKey(imagePngUri, publicKey);

    const createSignaturePayload: ISignatureCreateRequest = {
      signer,
      imageUrlEncryptedBase64,
      pinArgonHashNew,
    };

    const url = `${config.backendV2BaseUrl}/api/v1/customer/signatures`;
    const { status: responseStatus } = await this.httpUtils.postRequest<void, ISignatureCreateRequest>(
      url,
      createSignaturePayload,
    );

    if (responseStatus !== 201) {
      throw new Error(`CREATE signature failed. Http status ${responseStatus}`);
    }
  }

  public async updateSignature(
    signatureId: number,
    updatedSignature: IUpdateSignatureRawData,
    cryptoConfig: { pin: string; pinArgonHashConfig: IArgonHashConfig; publicKey: string },
  ): Promise<void> {
    const { pin, pinArgonHashConfig, publicKey } = cryptoConfig;

    const { hashHex: pinHash } = await this.cryptoService.createArgonPinHash(pin, pinArgonHashConfig);

    let imageUrlEncryptedBase64: string | undefined = undefined;
    if (updatedSignature.imagePngUri) {
      imageUrlEncryptedBase64 = await this.cryptoService.encryptWithPublicKey(updatedSignature.imagePngUri, publicKey);
    }

    let pinArgonHashNew = undefined;
    if (updatedSignature.newPin) {
      pinArgonHashNew = await this.cryptoService.createArgonPinHash(updatedSignature.newPin);
    }

    const updateRequestBody: ISignatureUpdateRequest = {
      id: signatureId,
      signer: updatedSignature.signer,
      pinArgonHashAuthorizationHex: pinHash,
      imageUrlEncryptedBase64,
      pinArgonHashNew,
    };

    const url = `${config.backendV2BaseUrl}/api/v1/customer/signatures`;
    const responseStatus = await this.httpUtils.patchRequest<ISignatureUpdateRequest>(url, updateRequestBody);

    if (responseStatus === 401) {
      throw new SignatureInvalidPinError();
    }

    if (responseStatus !== 200) {
      throw new Error(`UPDATE signature failed. Http status ${responseStatus}`);
    }
  }

  public async deleteSignature(signatureId: number): Promise<void> {
    const url = `${config.backendV2BaseUrl}/api/v1/customer/signatures`;
    const responseStatus = await this.httpUtils.deleteRequest<ISignatureDeleteRequestBody>(url, { id: signatureId });

    if (responseStatus !== 200) {
      throw new Error(`DELETE signature failed. Http status ${responseStatus}`);
    }
  }
}

function SignatureServiceFactory(): SignatureService {
  const cryptoService: ISignatureCryptoService = {
    encryptWithPublicKey: CryptoService.encryptWithPublicKey,
    createArgonPinHash: CryptoService.createArgonPinHash,
  };

  const httpUtils: ISignatureHttpUtils = {
    getRequest,
    postRequest,
    patchRequest,
    deleteRequest,
  };

  return new SignatureService(cryptoService, httpUtils);
}

export const SignatureServiceSingleton = SignatureServiceFactory();
