import { IHttpFailureDialogPayload } from '@/store/modules';

export const httpRequestFailureRetryDialogPayload: IHttpFailureDialogPayload = {
  title: 'Es ist ein Fehler aufgetreten',
  text: 'Beim Abrufen der Daten ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihre Verbindung und versuchen Sie es erneut.',
  buttons: [
    {
      text: 'Abbrechen',
      color: 'secondary',
      action: () => {
        return;
      },
    },
    {
      text: 'Wiederholung',
      color: 'primary',
      action: () => {
        return;
      },
    },
  ],
};
