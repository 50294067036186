import { MutationTree } from 'vuex';

import { IdanaSubscriptionPlan, IdanaOnboardingPlan } from '@/shared/interfaces';
import { IOrderState, IOrderStateAddress, IStripeCouponInfo } from './order.interfaces';
import { SupportedPaymentMethod } from '.';

export const orderMutations: MutationTree<IOrderState> = {
  institutionName(state, institutionName: string): void {
    state.institutionName = institutionName;
  },

  email(state, email: string): void {
    state.email = email;
  },

  isFreeAccount(state, isFreeAccount: boolean): void {
    state.isFreeAccount = isFreeAccount;
  },

  address(state, address: IOrderStateAddress): void {
    state.address = address;
  },

  streetAndHouseNumber(state, streetAndHouseNumber: string): void {
    state.address.streetAndHouseNumber = streetAndHouseNumber;
  },

  city(state, city: string): void {
    state.address.city = city;
  },

  zipCode(state, zipCode: string): void {
    state.address.zipCode = zipCode;
  },

  selectedSubscriptionPlan(state, selectedSubscriptionPlan: IdanaSubscriptionPlan | null): void {
    state.idanaSubscription.selectedSubscriptionPlan = selectedSubscriptionPlan;
  },

  isPlanWithDiscountSelected(state, isPlanWithDiscountSelected: boolean): void {
    state.idanaSubscription.isPlanWithDiscountSelected = isPlanWithDiscountSelected;
  },

  numberOfLicences(state, numberOfLicences: number): void {
    state.idanaSubscription.numberOfLicences = numberOfLicences;
  },

  selectedOnboardingPlan(state, selectedPlan: IdanaOnboardingPlan): void {
    state.onboarding.selectedPlan = selectedPlan;
  },

  isEditorSelected(state, isEditorSelected: boolean): void {
    state.idanaEditor.isEditorSelected = isEditorSelected;
  },

  paymentMethod(state, paymentMethod: SupportedPaymentMethod): void {
    state.paymentDetails.paymentMethod = paymentMethod;
  },

  stripePaymentMethodId(state, stripePaymentMethodId: string): void {
    state.paymentDetails.stripePaymentMethodId = stripePaymentMethodId;
  },

  stripeCouponId(state, couponId: string): void {
    state.idanaSubscription.stripeCouponId = couponId;
  },

  stripeCouponInfo(state, couponInfo: IStripeCouponInfo | null): void {
    state.idanaSubscription.stripeCouponInfo = couponInfo;
  },

  updateWholeState(state, restoredState: Record<string, unknown>) {
    /** This is needed for working with the order state as an object. */
    type IOrderStateWithIndex = IOrderState & Record<string, unknown>;

    for (const property in restoredState) {
      if (property in state) {
        (state as IOrderStateWithIndex)[property] = restoredState[property];
      }
    }
  },
};
