











































































import { Vue, Component, Emit, Ref, Watch, Prop } from 'vue-property-decorator';

type FormRule = (value: string) => true | string;
type VForm = Vue & { reset: () => void; validate: () => boolean };

@Component({})
export default class SignatureNewPinForm extends Vue {
  @Prop({ type: Boolean, required: false, default: false }) value!: boolean;

  @Ref('form') form!: VForm;

  protected showPin = false;
  protected isValid = false;
  protected formData = {
    pin: '',
    repeatPin: '',
  };

  protected get hasContent(): boolean {
    return !!this.$slots.title || !!this.$slots.description;
  }

  protected get formRules(): Record<string, FormRule[]> {
    return {
      pin: [(pin) => !!pin || 'Das ist ein Pflichtfeld!'],
      repeatPin: [
        (pin) => !!pin || 'Das ist ein Pflichtfeld!',
        (pin) => pin === this.formData.pin || 'Pins stimmen nicht überein!',
      ],
    };
  }

  protected revalidateForm(): void {
    this.form.validate();
  }

  @Watch('isValid')
  @Emit('input')
  protected validChanged(): boolean {
    return this.isValid;
  }

  public getPinIfValid(): string | null {
    if (!this.isValid) return null;
    return this.formData.pin;
  }

  public reset(): void {
    this.form.reset();
  }
}
