export const idanaOnboardingFeeBenefits: string[] = [
  'Einrichtung der GDT-Schnittstelle',
  'Einführungs-Workshop für Ihr Team',
  'Fragebogen Digitalisierung inklusive',
];

export const idanaSubscriptionPlanMonthlyBenefits: string[] = [
  'Unbegrenzte Anzahl an Befragungen',
  'Monatlich kündbar',
];

export const idanaSubscriptionPlanYearlyBenefits: string[] = [
  'Unbegrenzte Anzahl an Befragungen',
  '23% günstiger gegenüber Monatsabo',
];
