import { NavigationGuardNext, Route } from 'vue-router';

import { AuthServiceSingleton } from '@/services/auth/auth.service';
import { LocalStorage, StorageKeys } from '@/services/local-storage';

export function isAuthenticatedGuard(_to: Route, _from: Route, next: NavigationGuardNext): void {
  if (AuthServiceSingleton.isAuthenticated()) {
    next();
  } else {
    // unset requested route because deep links should not be supported in this case
    // clearing the storage is necessary because an "old" stored route could still be set
    LocalStorage.removeItem(StorageKeys.USER_REQUESTED_DEEPLINK_ROUTE);

    AuthServiceSingleton.logoutAndRedirectToLogin();
  }
}

export function isAuthenticatedGuardAllowDeeplinks(to: Route, _from: Route, next: NavigationGuardNext): void {
  if (AuthServiceSingleton.isAuthenticated()) {
    next();
  } else {
    // set requested route to be redirected to after login
    // this enables deep links even for unauthenticated users
    LocalStorage.setString(StorageKeys.USER_REQUESTED_DEEPLINK_ROUTE, to.path);

    AuthServiceSingleton.logoutAndRedirectToLogin();
  }
}

export function redirectToDashboardIfAuthenticated(_to: Route, _from: Route, next: NavigationGuardNext): void {
  if (AuthServiceSingleton.isAuthenticated()) {
    next('/');
  } else {
    next();
  }
}
