import { IAppState } from '@/store';
import { IHttpFailureDialogState } from '@/store/modules';
import { httpFailureDialogActions } from '@/store/modules/http-failure-dialog/http-failure-dialog.actions';
import { httpFailureDialogGetters } from '@/store/modules/http-failure-dialog/http-failure-dialog.getters';
import { httpFailureDialogMutations } from '@/store/modules/http-failure-dialog/http-failure-dialog.mutations';
import { httpFailureDialogStateDefault } from '@/store/modules/http-failure-dialog/http-failure-dialog.state';
import { Module } from 'vuex';

export const httpFailureDialogModule: Module<IHttpFailureDialogState, IAppState> = {
  namespaced: true,
  state: JSON.parse(JSON.stringify(httpFailureDialogStateDefault)) as IHttpFailureDialogState,
  getters: httpFailureDialogGetters,
  mutations: httpFailureDialogMutations,
  actions: httpFailureDialogActions,
};
