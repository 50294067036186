import { NavigationGuardNext, Route } from 'vue-router';

import store from '@/store';

export async function redirectBasedOnSubscriptionStatus(
  _to: Route,
  _from: Route,
  next: NavigationGuardNext,
): Promise<void> {
  await store.dispatch('profile/fetchSubscriptionStatus');

  const isSubscriptionAccount: boolean = store.getters['profile/isSubscriptionAccount'];
  const isFreeAccount: boolean = store.getters['profile/isFreeAccount'];

  if (isSubscriptionAccount || isFreeAccount) {
    next();
    return;
  }

  await store.dispatch('order/restoreFromLocalStorage');

  const isFreeAccountMode = store.state.order.isFreeAccount;

  if (isFreeAccountMode) {
    next('/order-subscription/address');
  } else {
    next('/order-subscription/licences');
  }
}
