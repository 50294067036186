






















































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { IDropdownItem } from './medical-specialties.interfaces';

@Component
export default class SignUpMedicalSpecialtiesPresentation extends Vue {
  @Prop({ required: false, type: Array, default: () => [] })
  protected selectedMedicalSpecialties!: IDropdownItem[];
  @Prop({ required: true, type: Array })
  protected allMedicalSpecialties!: IDropdownItem[];
  @Prop({ type: Boolean, required: false, default: false })
  protected readonly isMediumMobileDevice!: boolean;

  protected selectedMedicalSpecialtiesInternal: IDropdownItem[] = [];

  mounted() {
    this.selectedMedicalSpecialtiesInternal = [...this.selectedMedicalSpecialties];
  }

  @Watch('selectedMedicalSpecialties')
  onSelectedMedicalSpecialtiesChange(specialties: IDropdownItem[]) {
    this.selectedMedicalSpecialtiesInternal = [...specialties];
  }

  protected get isValid(): boolean {
    return this.selectedMedicalSpecialtiesInternal.length > 0;
  }

  @Emit('back')
  protected back(): void {
    return undefined;
  }

  @Emit('change')
  protected change(): IDropdownItem[] {
    return this.selectedMedicalSpecialtiesInternal;
  }

  @Emit('register')
  protected register(): IDropdownItem[] {
    return this.selectedMedicalSpecialtiesInternal;
  }
}
