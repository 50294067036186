








































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class FreeAccountContentItem extends Vue {
  @Prop({ required: true, type: String }) title!: string;
  @Prop({ required: true, type: String }) description!: string;
  @Prop({ required: true, type: String }) link!: string;
  @Prop({ required: true, type: String }) linkText!: string;
  @Prop({ required: true, type: String }) icon!: string;
  @Prop({ required: false, type: Boolean, default: false }) shadow!: boolean;
  @Prop({ required: false, type: Boolean, default: false }) dark!: boolean;

  protected get openLinkInNewTab(): boolean {
    return this.link.includes('http');
  }
}
