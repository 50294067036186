import { IAppState } from '@/store';
import { IHttpFailureDialogConfig, IHttpFailureDialogPayload, IHttpFailureDialogState } from '@/store/modules';
import { httpFailureDialogConfigDefault } from '@/store/modules/http-failure-dialog/http-failure-dialog.state';
import { ActionTree } from 'vuex';

export const httpFailureDialogActions: ActionTree<IHttpFailureDialogState, IAppState> = {
  showDialog(store, payload: IHttpFailureDialogPayload) {
    const dialogConfig: IHttpFailureDialogConfig = Object.assign({}, payload, { isVisible: true });

    store.commit('updateDialogConfig', dialogConfig);
  },

  hideDialog(store) {
    const dialogConfig: IHttpFailureDialogConfig = Object.assign({}, httpFailureDialogConfigDefault);

    store.commit('updateDialogConfig', dialogConfig);
  },
};
