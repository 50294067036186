























































import { Vue, Component } from 'vue-property-decorator';

import OnboardingSteps from './OnboardingSteps.vue';

@Component({
  components: {
    OnboardingSteps,
  },
})
export default class Onboarding extends Vue {
  protected readonly IDANA_HELP_CENTER_URL = 'https://hilfe.idana.com';

  protected readonly DOWNLOAD_URL =
    'https://hilfe.idana.com/kb/guide/de/so-installieren-sie-idana-KMyDCGEIRH/Steps/307428';
}
