import { IdanaSubscriptionPlan } from '@/shared/interfaces';

import { IAppState } from '@/store';
import {
  getDiscountMonthsCount,
  getPlanPricePerYearWithDiscount,
  getPriceValueWithDiscount,
} from '@/store/modules/order/order.utils';
import { GetterTree } from 'vuex';
import { IOrderState } from './order.interfaces';

export const orderGetters: GetterTree<IOrderState, IAppState> = {
  isSubscriptionAddressValid(state): boolean {
    return !!(
      state.institutionName &&
      state.email &&
      state.address &&
      state.address.streetAndHouseNumber &&
      state.address.zipCode &&
      state.address.city
    );
  },

  isSubscriptionPlanSelected(state): boolean {
    return !!state.idanaSubscription.selectedSubscriptionPlan;
  },

  isSubscriptionPaymentSet(state): boolean {
    return !!(state.paymentDetails.paymentMethod && state.paymentDetails.stripePaymentMethodId);
  },

  isMonthlyPlanSelected(state): boolean {
    return state.idanaSubscription.selectedSubscriptionPlan === IdanaSubscriptionPlan.IDANA_FLEXIBLE;
  },

  subscriptionPlanWithDiscount(state): IdanaSubscriptionPlan | null {
    if (!state.idanaSubscription.stripeCouponInfo) return null;

    return state.idanaSubscription.stripeCouponInfo.idanaSubscriptionPlan || null;
  },

  subscriptionDiscountPercent(state): number {
    const couponInfo = state.idanaSubscription.stripeCouponInfo;

    return couponInfo && couponInfo.percentOff ? couponInfo.percentOff : 0;
  },

  yearlyPlanPriceBenefit(state): number {
    const monthlyPlanYearlyCost = state.idanaSubscription.subscriptionPrices[IdanaSubscriptionPlan.IDANA_FLEXIBLE] * 12;
    const yearlyPlanYearlyCost = state.idanaSubscription.subscriptionPrices[IdanaSubscriptionPlan.IDANA_PLUS] * 12;

    return Number(monthlyPlanYearlyCost - yearlyPlanYearlyCost) * state.idanaSubscription.numberOfLicences;
  },

  specialPlanPriceWithDiscount(state, getters): number {
    if (!state.idanaSubscription.stripeCouponInfo) return 0;

    const planWithDiscount = state.idanaSubscription.stripeCouponInfo.idanaSubscriptionPlan;

    if (!planWithDiscount) {
      return 0;
    }

    const price = state.idanaSubscription.subscriptionPrices[planWithDiscount];
    const discountPercent = getters.subscriptionDiscountPercent;

    return getPriceValueWithDiscount(price, discountPercent);
  },

  specialPlanDiscountMonthsDuration(state): number {
    if (!state.idanaSubscription.stripeCouponInfo) return 0;

    const specialPlan = state.idanaSubscription.stripeCouponInfo.idanaSubscriptionPlan;

    if (!specialPlan) return 0;

    return getDiscountMonthsCount(state.idanaSubscription.stripeCouponInfo);
  },

  specialPlanYearlyCostWithDiscount(state, getters): number {
    if (!state.idanaSubscription.stripeCouponInfo) return 0;

    const specialPlan = state.idanaSubscription.stripeCouponInfo.idanaSubscriptionPlan;

    if (!specialPlan) return 0;

    const specialPlanPriceWithoutDiscount = state.idanaSubscription.subscriptionPrices[specialPlan];

    return getPlanPricePerYearWithDiscount(
      state.idanaSubscription.stripeCouponInfo.percentOff,
      specialPlanPriceWithoutDiscount,
      getters.specialPlanDiscountMonthsDuration,
    );
  },

  specialPlanYearlyDiscountedPriceBenefit(state, getters): number {
    if (!state.idanaSubscription.stripeCouponInfo) return 0;
    const selectedPlan = state.idanaSubscription.selectedSubscriptionPlan;

    if (!selectedPlan) return 0;

    const selectedPlanYearlyCost = state.idanaSubscription.subscriptionPrices[selectedPlan] * 12;

    return (
      (selectedPlanYearlyCost - getters.specialPlanYearlyCostWithDiscount) * state.idanaSubscription.numberOfLicences
    );
  },

  selectedPlanTotalCost(state, getters): number {
    const selectedProduct = state.idanaSubscription.selectedSubscriptionPlan;

    if (!selectedProduct) return 0;

    const productPrice = state.idanaSubscription.subscriptionPrices[selectedProduct];
    const licencesCount = state.idanaSubscription.numberOfLicences;

    return getters.isMonthlyPlanSelected ? productPrice * licencesCount : productPrice * licencesCount * 12;
  },

  selectedPlanTotalCostWithDiscount(state, getters): number {
    const finalPriceWithoutDiscount = getters.selectedPlanTotalCost;
    const discountPercent = getters.subscriptionDiscountPercent;

    if (!state.idanaSubscription.isPlanWithDiscountSelected) return finalPriceWithoutDiscount;

    if (getters.isMonthlyPlanSelected) {
      return getPriceValueWithDiscount(finalPriceWithoutDiscount, discountPercent);
    }

    return getters.specialPlanYearlyCostWithDiscount * state.idanaSubscription.numberOfLicences;
  },

  totalPlanCostWithTaxes(state, getters): number {
    return getters.selectedPlanTotalCost * 1.19;
  },

  totalPlanCostWithTaxesAndDiscount(state, getters): number {
    return getters.selectedPlanTotalCostWithDiscount * 1.19;
  },

  totalTaxesCost(state, getters): number {
    return state.idanaSubscription.isPlanWithDiscountSelected
      ? getters.selectedPlanTotalCostWithDiscount * 0.19
      : getters.selectedPlanTotalCost * 0.19;
  },

  discountSavingsPerPeriod(state, getters): number {
    if (!state.idanaSubscription.stripeCouponInfo) return 0;

    return getters.selectedPlanTotalCost - getters.selectedPlanTotalCostWithDiscount;
  },
};
