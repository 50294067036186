import Step1Image from './assets/step_1.png';
import Step2Image from './assets/step_2.png';
import Step3Image from './assets/step_3.png';
import Step4Image from './assets/step_4.png';

export interface Step {
  id: string;
  linkToService: string;
  linkText: string;
  linkDoneText: string;
  headline: string;
  isDone: boolean;
  isLocked: boolean;
  text: string;
  icon: string;
  image: string;
}

/** Content of onboarding steps.
 * This contains a placeholder `{{customerId}}`
 * that must be replaced by the actual customer id before using it.
 */
export const onboardingSteps: Step[] = [
  {
    id: 'planningMeeting',
    linkToService: 'https://calendly.com/erik-mert/planungsgesprach?&utm_source=onboarding-portal',
    linkText: 'Gespräch buchen',
    linkDoneText: 'Gespräch vereinbart',
    text: 'Besprechen wir gemeinsam, wie ein reibungsloser Start gelingt. Wir empfehlen das Gespräch frühestens zwei Wochen vor Ihrem Start.',
    headline: 'Planungsgespräch',
    isDone: false,
    isLocked: false,
    icon: 'mdi-phone-in-talk-outline',
    image: Step1Image,
  },
  {
    id: 'getToKnowUsForm',
    linkToService:
      'https://docs.google.com/forms/d/e/1FAIpQLSfPIcCLzrWdW9EFWdk6PFY-B_L6CR-XBN92sR7xNhGcVY3DFQ/viewform?usp=pp_url&entry.1518159092={{customerId}}',
    linkText: 'Zum Formular',
    linkDoneText: 'Formular ausgefüllt',
    text: 'Füllen Sie zur Vorbereitung unser Neukundenformular aus, damit wir Sie im Planungsgespräch bestmöglich beraten können.',
    headline: 'Neukundenformular',
    isDone: false,
    isLocked: false,
    icon: 'mdi-pencil-outline',
    image: Step2Image,
  },
  {
    id: 'pvsConnection',
    linkToService:
      'https://calendly.com/d/dry-nyz-vmv?utm_campaign=OKR3&utm_source=Onboardingportal&utm_medium=Website',
    linkText: 'Techniktermin buchen',
    linkDoneText: 'Techniktermin vereinbart',
    text: 'Unser Technikexperte nimmt die Anbindung Ihrer Praxissoftware an Idana per Teamviewer oder Anydesk vor.',
    headline: 'Praxissoftware anbinden',
    isDone: false,
    isLocked: false,
    icon: 'mdi-laptop',
    image: Step3Image,
  },
  {
    id: 'trainingOffer',
    linkToService:
      'https://calendly.com/d/32m-9hj-vws?&utm_source=onboarding-portal&utm_medium=Website',
    linkText: 'Schulung buchen',
    linkDoneText: 'Schulung vereinbart',
    text: 'Profitieren Sie von unserer Erfahrung. Wir erklären Ihrem Team die Funktionen von Idana im Detail und üben den Einsatz. ',
    headline: 'Teamschulung',
    isDone: false,
    isLocked: false,
    icon: 'mdi-school-outline',
    image: Step4Image,
  },
];
