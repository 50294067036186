












































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { ICreateApiKeyDialog } from './api-keys.interfaces';

import { ApiKeyDialogCancelledError } from './api-keys.errors';

import ApiKeyDescriptionForm from './ApiKeyDescriptionForm.vue';

@Component({
  components: {
    ApiKeyDescriptionForm,
  },
})
export default class CreateApiKeyDialog extends Vue implements ICreateApiKeyDialog {
  @Prop({ type: Boolean, default: false }) fullscreen!: boolean;

  protected isVisible = false;
  protected isLoading = false;

  private resolveAction: ((apiKeyName: string) => void) | null = null;
  private rejectAction: ((error: Error) => void) | null = null;
  protected isDescriptionFormValid = false;

  @Ref('descriptionForm') protected descriptionForm!: ApiKeyDescriptionForm;

  protected get isValid(): boolean {
    return this.isDescriptionFormValid;
  }

  public async show(): Promise<string> {
    this.isVisible = true;

    return new Promise((resolve, reject) => {
      this.resolveAction = resolve;
      this.rejectAction = reject;
    });
  }

  public setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  public hide(): void {
    this.isVisible = false;
  }

  protected closeDialog(): void {
    if (!this.rejectAction) return;
    this.rejectAction(new ApiKeyDialogCancelledError());
    this.isVisible = false;
  }

  @Watch('isVisible')
  protected onIsVisibleChanged(isVisible: boolean) {
    if (!isVisible) this.resetDialog();
  }

  protected createApiKey() {
    if (!this.resolveAction || !this.rejectAction) {
      this.hide();
      return;
    }

    const description = this.descriptionForm.getDescriptionIfValid();

    if (!description) {
      this.rejectAction(new Error('Etwas ist schiefgelaufen'));
      return;
    }

    this.resolveAction(description.name);
  }

  private resetDialog(): void {
    this.isLoading = false;

    this.resolveAction = null;
    this.rejectAction = null;

    this.descriptionForm.reset();
  }
}
