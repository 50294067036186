export interface IAuth0Config {
  clientID: string;
  domain: string;
  redirectUriBase: string;
  audience: string;
  scope: string;
  refreshTokenScope: string;
  responseType: string;
}

export interface IStripeConfig {
  publicKey: string;
}

export interface IPlausibleConfig {
  domain: string;
  trackLocalhost: boolean;
}

export enum Environment {
  PRODUCTION = 'production',
  DEVELOPMENT = 'development',
}

export interface IConfig {
  onboardingFeeStartDate: Date;
  environment: Environment;
  backendV2BaseUrl: string;
  auth0Config: IAuth0Config;
  stripeConfig: IStripeConfig;
  plausible: IPlausibleConfig;
  questionnairesV2BaseUrl: string;
}
