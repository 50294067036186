



































































import store from '@/store';
import { Component, Vue } from 'vue-property-decorator';

import { LocalStorage, StorageKeys } from '@/services/local-storage';

@Component
export default class SignUpInstitutionInfo extends Vue {
  protected institution = '';
  protected agreementCheck = false;

  protected created() {
    const institutionName = LocalStorage.getString(StorageKeys.INSTITUTION_NAME);
    if (institutionName) {
      this.institution = institutionName;
    }
  }

  protected get isMediumMobileDevice(): boolean {
    return store.getters['layoutModule/isMobileDeviceWidthExcludingLarge'];
  }

  protected get isFreeAccountMode(): boolean {
    return store.state.order.isFreeAccount;
  }

  protected get isValid(): boolean {
    return !!this.institution && this.agreementCheck;
  }

  protected navigateToNextStep() {
    LocalStorage.setString(StorageKeys.INSTITUTION_NAME, this.institution);
    this.$router.push({ path: '/sign-up/medical-specialties' });
  }
}
