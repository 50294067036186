



















import { Vue, Prop, Component } from 'vue-property-decorator';

import qrCodeDev from '@/modules/account/signatures/assets/signatures-qr-code-dev.svg';
import qrCodeProd from '@/modules/account/signatures/assets/signatures-qr-code-prod.svg';
import config from '@/config';
import { Environment } from '@/config/config.interfaces';

export interface ISignatureDashboardQrCodeOverlay {
  showOverlay(): void;
}

@Component
export default class SignatureDashboardQrCodeOverlay extends Vue implements ISignatureDashboardQrCodeOverlay {
  @Prop({ type: Boolean, required: true }) isOverlayShown!: boolean;

  protected getQrCodeSrc() {
    return config.environment === Environment.DEVELOPMENT ? qrCodeDev : qrCodeProd;
  }

  protected hideOverlay() {
    this.isOverlayShown = false;
  }

  public showOverlay() {
    this.isOverlayShown = true;
  }
}
