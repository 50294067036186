




















































































import { PropType } from 'vue';
import { Vue, Component, Ref, Prop, Watch, Emit } from 'vue-property-decorator';

import { ISigner } from './signature.interfaces';

type FormRule = (value: string) => true | string;
type VForm = Vue & { reset: () => void };

@Component({})
export default class SignerForm extends Vue {
  @Prop({ type: Boolean, required: false, default: false }) value!: boolean;
  @Prop({ type: Object as PropType<ISigner>, required: false }) initialData!: ISigner | null;

  @Ref('form') form!: VForm;

  protected isValid = false;
  protected formData: ISigner = {
    title: '',
    firstName: '',
    lastName: '',
    description: '',
  };

  protected get formRules(): Record<string, FormRule[]> {
    return {
      lastName: [(name) => !!name || 'Nachname ist ein Pflichtfeld!'],
      description: [(desc) => !!desc || 'Beschreibung ihrer Tätigkeit ist ein Pflichtfeld!'],
    };
  }

  @Watch('initialData', { immediate: true })
  protected onInitialDataChanged(): void {
    if (this.initialData) {
      this.formData = { ...this.initialData };
    }
  }

  @Watch('isValid')
  @Emit('input')
  protected validChanged(): boolean {
    return this.isValid;
  }

  public getSignerIfValid(): ISigner | null {
    if (!this.isValid) return null;
    this.formData.title = this.formData.title || '';
    this.formData.firstName = this.formData.firstName || '';
    return this.formData;
  }

  public reset(): void {
    this.form.reset();
  }
}
