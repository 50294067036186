import {
  IRoutingStepperConfigItem,
  IRoutingStepperConfigItemExtended,
} from '@/shared/routing-stepper/routing-stepper.interfaces';

export const getActiveRouteIndex = (stepperItems: IRoutingStepperConfigItem[], routePath: string): number => {
  return stepperItems.findIndex((stepperItem: IRoutingStepperConfigItem) => stepperItem.routePath === routePath);
};

export const getExtendedStepperItems = (
  stepperItems: IRoutingStepperConfigItem[],
  activeIndex: number,
): IRoutingStepperConfigItemExtended[] => {
  return stepperItems.map((item: IRoutingStepperConfigItem, index: number) => ({
    routePath: item.routePath,
    label: item.label,
    isActive: index < activeIndex,
    isCurrent: index === activeIndex,
  }));
};
