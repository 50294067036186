import Plausible from 'plausible-tracker';
import { NavigationGuardNext, Route } from 'vue-router';

import config from '@/config';

const { trackPageview } = Plausible({
  domain: config.plausible.domain,
  trackLocalhost: config.plausible.trackLocalhost,
});

export function plausibleTracking(_to: Route, _from: Route, next: NavigationGuardNext) {
  // ignore these routes because they contain sensitive user information
  // e.g. in the access token as part of the query string
  if (!window.location.href.includes('auth-callback') && !window.location.href.includes('sign-up/credentials')) {
    trackPageview();
  }

  next();
}
