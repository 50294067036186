











































































import { isBrowserOutdated } from '@/utils/browser-detection-helpers';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class OutdatedSupportedBrowserMessage extends Vue {
  protected isVisible = true;

  protected mounted(): void {
    this.isVisible = isBrowserOutdated();
  }
}
