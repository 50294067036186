

















import { IdanaSubscriptionPlan } from '@/shared/interfaces';
import { Component, Emit, Vue } from 'vue-property-decorator';

@Component
export default class SubscriptionSelector extends Vue {
  protected switchValue = true;
  @Emit('change') change(switchValue: boolean) {
    return switchValue ? IdanaSubscriptionPlan.IDANA_PLUS : IdanaSubscriptionPlan.IDANA_FLEXIBLE;
  }
}
