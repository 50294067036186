import { IdanaOnboardingPlan, IdanaSubscriptionPlan } from '@/shared/interfaces';

export interface IStripeIntentResponse {
  clientSecret: string;
}

export interface ICustomer {
  id: number;
  auth0Email: string;
  // auth0UserId: string; // not used here
  institutionName: string | null;
  stripeCustomerId: string | null;
  // stripeSubscriptionId: string | null; // not used here
  hubspotDealId: string | null;
  // registrationTimestamp: string; // not used here
  // subprocessorAgreementTimestamp: string | null; // not used here
  // freeAccountUntilTimestamp: string | null; // not used here
  publicKey: string | null;
  // billByPayPerUse: boolean; // not used here
}

export interface IRegistrationInfo {
  institutionName: string;
  medicalSpecialties: number[];
  hubspotDealId?: string;
}

export interface IBillingAddress {
  billingInstitution: string;
  billingEmail: string;
  streetAndHouseNumber: string;
  zipCode: string;
  city: string;

  /** Only Hardcoded for Development. Should be choosable by user. */
  countryCode: 'DE';
}

export interface IOrderSubscriptionPayload {
  billingAddress: IBillingAddress;
  selectedIdanaSubscription: IdanaSubscriptionPlan;
  numberOfLicences: number;
  idanaEditorSelected: boolean;
  stripePaymentMethodId: string;
  onboardingTier: IdanaOnboardingPlan;
  stripeCouponId?: string;
}

export interface IFreeSubscriptionPayload {
  billingAddress: IBillingAddress;
}

export interface IOnboardingStatus {
  planningMeetingDone: boolean;
  getToKnowUsForm: boolean;
  pvsConnectionDone: boolean;
  trainingOfferDone: boolean;
  [key: string]: boolean;
}

export enum OnboardingStatusPatchKey {
  PLANNING_MEETING_DONE = 'planningMeetingDone',
  GET_TO_KNOW_US_FORM_DONE = 'getToKnowUsFormDone',
  PVS_CONNECTION_DONE = 'pvsConnectionDone',
  TRAINING_OFFER_DONE = 'trainingOfferDone',
}

export interface IOnboardingPatchBody {
  [OnboardingStatusPatchKey: string]: boolean;
}

export enum CustomerRegistrationHTTPStatusCode {
  /** new registration. new account has been created in the backend/database */
  CREATED = 201,
  /** no new registration. account already exists in the backend/database */
  ALREADY_EXISTS = 200,
}

export interface IMedicalSpecialty {
  id: number;
  name: string;
}
