import { AuthServiceSingleton } from '../auth/auth.service';

function getHeaders() {
  return {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + AuthServiceSingleton.getAccessToken(),
  };
}

async function issueRequestWithBody<RequestData>(method: string, url: string, data: RequestData) {
  const options: RequestInit = {
    method,
    headers: getHeaders(),
    body: JSON.stringify(data),
  };

  return fetch(url, options);
}

export async function postRequestEmptyResponse<RequestData = unknown>(url: string, data: RequestData): Promise<void> {
  await issueRequestWithBody('POST', url, data);
}

export async function postRequest<ResponseData = void, RequestData = unknown>(
  url: string,
  data: RequestData,
): Promise<{ status: number; data: ResponseData }> {
  const response = await issueRequestWithBody('POST', url, data);

  const responseData = await response.json();

  if (responseData.error) {
    throw responseData;
  }

  return {
    status: response.status,
    data: responseData.data as ResponseData,
  };
}

export async function getRequest<ResponseData = unknown>(url: string): Promise<ResponseData> {
  const options: RequestInit = {
    method: 'GET',
    headers: getHeaders(),
  };

  const response = await fetch(url, options);
  const responseData = await response.json();

  if (responseData.error) {
    throw responseData;
  }

  return responseData.data as ResponseData;
}

export async function patchRequest<RequestData = unknown>(url: string, data: RequestData): Promise<number> {
  const response = await issueRequestWithBody('PATCH', url, data);

  return response.status;
}

export async function deleteRequest<RequestData = unknown>(url: string, data: RequestData): Promise<number> {
  const response = await issueRequestWithBody('DELETE', url, data);
  return response.status;
}

export async function putRequest<RequestData = unknown>(url: string, data: RequestData): Promise<number> {
  const response = await issueRequestWithBody('PUT', url, data);
  return response.status;
}
