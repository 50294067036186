











import store from '@/store';
import { Component, Vue } from 'vue-property-decorator';

import { AuthServiceSingleton } from '@/services/auth/auth.service';
import { BackendService } from '@/services/backend';
import { LocalStorage, StorageKeys } from '@/services/local-storage';

import SignUpMedicalSpecialtiesPresentation from './SignUpMedicalSpecialtiesPresentation.vue';
import { IDropdownItem } from './medical-specialties.interfaces';

@Component({
  components: { SignUpMedicalSpecialtiesPresentation },
})
export default class SignUpMedicalSpecialties extends Vue {
  protected selectedMedicalSpecialties: IDropdownItem[] = [];
  protected allMedicalSpecialties: IDropdownItem[] = [];

  protected get isMediumMobileDevice(): boolean {
    return store.getters['layoutModule/isMobileDeviceWidthExcludingLarge'];
  }

  protected async created() {
    const specialties = await BackendService.getMedicalSpecialties();

    this.allMedicalSpecialties = specialties.map((specialty) => ({
      text: specialty.name,
      value: specialty.id,
    }));

    const previouslySelectedMedicalSpecialties = LocalStorage.getString(StorageKeys.MEDICAL_SPECIALTIES);
    this.selectedMedicalSpecialties = previouslySelectedMedicalSpecialties
      ? JSON.parse(previouslySelectedMedicalSpecialties)
      : [];
  }

  protected back(): void {
    this.$router.push({ path: '/sign-up/institution-info' });
  }

  protected change(selectedMedicalSpecialties: IDropdownItem) {
    LocalStorage.setString(StorageKeys.MEDICAL_SPECIALTIES, JSON.stringify(selectedMedicalSpecialties));
  }

  protected register(selectedMedicalSpecialties: IDropdownItem): void {
    LocalStorage.setString(StorageKeys.MEDICAL_SPECIALTIES, JSON.stringify(selectedMedicalSpecialties));
    AuthServiceSingleton.register();
  }
}
