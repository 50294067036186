import config from '@/config';
import { deleteRequest, getRequest, postRequest } from '@/services/backend/backend.utils';
import { HttpRequestsHandler } from '@/shared/http-requests-handler';
import { IApiKeysHttpService } from './api-keys.business-logic-service';
import { IApiKeyResponseData } from './api-keys.interfaces';

interface IApiKeyHttpUtils {
  getRequest<ResponseData>(url: string): Promise<ResponseData>;
  deleteRequest<RequestData>(url: string, data?: RequestData): Promise<number>;
  postRequest<ResponseData, RequestData>(
    url: string,
    data?: RequestData,
  ): Promise<{ status: number; data: ResponseData }>;
}

export interface IApiKeyCreateResponse {
  name: string;
}

export interface IApiKeyCreateRequest {
  name: string;
}

export interface IApiKeyDeleteRequestBody {
  id: number;
}

export interface IApiKeyCreated {
  name: string;
  apiKey: string;
}

export class ApiKeysHttpService implements IApiKeysHttpService {
  constructor(private httpUtils: IApiKeyHttpUtils) {}

  public async getAllApiKeys(): Promise<IApiKeyResponseData[]> {
    const url = `${config.backendV2BaseUrl}/api/v1/customer/api-keys`;
    return this.httpUtils.getRequest<IApiKeyResponseData[]>(url);
  }

  public async createApiKey(apiKeyName: string): Promise<string> {
    const url = `${config.backendV2BaseUrl}/api/v1/customer/api-keys`;
    const { data } = await HttpRequestsHandler.handleRequestWithRetry(() =>
      this.httpUtils.postRequest<IApiKeyCreated, IApiKeyCreateRequest>(url, {
        name: apiKeyName,
      }),
    );
    return data.apiKey;
  }

  public async deleteApiKey(apiKeyId: number): Promise<void> {
    const url = `${config.backendV2BaseUrl}/api/v1/customer/api-keys`;
    const responseStatus = await HttpRequestsHandler.handleRequestWithRetry(() =>
      this.httpUtils.deleteRequest<IApiKeyDeleteRequestBody>(url, { id: apiKeyId }),
    );

    if (responseStatus !== 204) {
      throw new Error(`DELETE api-key failed. Http status ${responseStatus}`);
    }
  }
}

function ApiKeysHttpServiceFactory(): ApiKeysHttpService {
  const httpUtils: IApiKeyHttpUtils = {
    getRequest: getRequest,
    postRequest: postRequest,
    deleteRequest,
  };

  return new ApiKeysHttpService(httpUtils);
}

export const ApiKeysHttpServiceSingleton = ApiKeysHttpServiceFactory();
