









































import { Component, Vue } from 'vue-property-decorator';

import store from '@/store';

import idanaLogo from '@/assets/idana-logo-white.svg';
import idanaLogoSmall from '@/assets/idana-icon-white.svg';
import { AuthServiceSingleton } from '@/services/auth/auth.service';

@Component
export default class AppHeader extends Vue {
  protected idanaLogo = idanaLogo;
  protected idanaLogoSmall = idanaLogoSmall;

  protected get institutionName(): string {
    if (!store.state.profile.customer) return '';
    return store.state.profile.customer.institutionName || '';
  }

  protected logOut(): void {
    AuthServiceSingleton.logoutAndRedirectToLogin();
  }
}
