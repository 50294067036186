/*
 * kudos to https://github.com/atanas-dev
 * for creating this solution here
 * https://github.com/atanas-dev/vue-router-multiguard
 * IMPORTANT:
 *   Am not using this package, as this is custom solution, no need for extra dependency.
 *   Might only need change when migrating to Vue3 or bigger
 * */

import { NavigationGuard, NavigationGuardNext, Route } from 'vue-router';

function evaluateGuards(guards: NavigationGuard[], to: Route, from: Route, next: NavigationGuardNext) {
  const guardsLeft = guards.slice(0); // clone the array so we do not accidentally modify it
  const nextGuard = guardsLeft.shift();

  if (nextGuard === undefined) {
    next();
    return;
  }

  nextGuard(to, from, (switchTo) => {
    if (switchTo === undefined) {
      evaluateGuards(guardsLeft, to, from, next);
      return;
    }

    next(switchTo);
  });
}

export const multiGuard = (guards: NavigationGuard[]) => {
  if (!Array.isArray(guards)) {
    throw new Error('You must specify an array of guards');
  }

  return (to: Route, from: Route, next: NavigationGuardNext) => {
    return evaluateGuards(guards, to, from, next);
  };
};
